<template>
  <div class="c-footer"></div>
</template>

<script>
export default {
    name: 'Footer'

}
</script>

<style>

.c-footer{
    grid-area: c-footer;
    background: #943634;
}

</style>