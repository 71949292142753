<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-data-iterator item-key="id"
    :items="metricasProdutos" :items-per-page.sync="itensPorPagina" :page.sync="pagina"
      :search="search" :sort-by="Object.keys(sortBy)" :sort-desc="sortDesc" hide-default-footer>
      <template v-slot:header>
        <v-toolbar dark color="#943634" class="mb-9">
          <v-text-field v-model="search" color="#943634" clearable flat solo-inverted hide-details
            prepend-inner-icon="mdi-magnify" label="Buscar Pregão"></v-text-field>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer></v-spacer>
            <v-select v-model="sortBy" flat solo-inverted hide-details :items="keys" color="#943634"
              prepend-inner-icon="mdi-magnify" label="Selecione a ordem" class="c-select-ordem" clearable>
              <template v-slot:selection="{ item }">
                <span class="u-destaque-select">{{ Object.values(item)[0] }}</span>
              </template>

              <template v-slot:item="{ item }">
                <v-card width="100%" flat class="ma-0 pa-0" color="#943634">
                  <v-list dark dense color="#BA4441" class="ma-0 pa-0">
                    <v-list-item v-for="(valor, idx) in item" :key="idx">
                      <v-list-item-title class="font-weight-bold pa-0 ma-0">{{
                      valor
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </template>
            </v-select>
            <v-spacer></v-spacer>
            <v-btn-toggle v-model="sortDesc" mandatory>
              <v-btn large depressed color="#BA4441" :value="false">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn large depressed color="#BA4441" :value="true">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-btn elevation="0" color="#BA4441" small fab class="ml-4" @click="fecharMetricas()"><v-icon color="#E3B1B0">cancel</v-icon></v-btn>
          </template>
        </v-toolbar>
        <v-row justify="center" align="center" align-content="center" class="mb-0 mt-n4">
          <v-col class="ma-0 pa-0 px-15 pb-2 d-flex flex-row justify-end align-center flex-nowrap">
            <div v-if="!ativarResumo" class="ma-0 pa-0">
              <v-fade-transition appear>
                <div class="c-resumo-simples ma-0 pa-0 pr-3">
                  <v-img contain width="80px" height="auto" position="center center" :src="produtoDefinido.imageUrl"
                    class="rounded-lg"></v-img>
                  <v-chip label color="#a34441" class="white--text">{{ produtoDefinido.name }}</v-chip>
                </div>
              </v-fade-transition>
            </div>
            <v-chip color="#BA4441" class="white--text pl-5 mr-3">PREGÕES ATIVOS
              <v-switch class="ma-0 pa-0 pt-4 pl-2" dense color="white" v-model="pregoesAtivos">
              </v-switch>
            </v-chip>
            <v-chip color="#BA4441" class="white--text pl-5 mr-3">ALTERAR DADOS
              <v-switch class="ma-0 pa-0 pt-4 pl-2" dense color="white" v-model="editarValorCompraEUnidade">
              </v-switch>
            </v-chip>
            <v-chip color="#BA4441" class="white--text pl-5 mr-3">RESUMO
              <v-switch class="ma-0 pa-0 pt-4 pl-2" dense color="white" v-model="ativarResumo"
                @change="salvarDefinicoes()">
              </v-switch>
            </v-chip>
            <v-chip color="#BA4441" class="white--text pl-5">GRÁFICOS
              <v-switch class="ma-0 pa-0 pt-4 pl-2" dense color="white" v-model="ativarGraficos"
                @change="salvarDefinicoes()">
              </v-switch>
            </v-chip>
          </v-col>
          <v-col v-if="ativarResumo" cols="12" sm="12" class="px-15 mt-0 pt-2 u-relative">
            <v-sheet color="#e8e8e8" elevation="2" height="auto" width="100%" class="rounded-lg ma-0 text-center">
              <v-row justify="space-between" align="center" align-content="center" class="ma-0 pa-0">
                <v-col cols="12" sm="1" class="ma-0 pa-2 text-left c-imggg">
                  <v-img contain width="80px" height="auto" position="center center" :src="produtoDefinido.imageUrl"
                    class="rounded-lg"></v-img>
                </v-col>
                <v-col cols="12" sm="6" class="ma-0 pa-0 text-left">
                  <p class="mb-0 font-weight-black u-color text-uppercase pt-3">
                <v-autocomplete
                      v-model="produtoDefinido"
                      :items="todosProdutos"
                      item-text="name"
                      item-value="name"
                      dense
                      solo
                      flat
                      background-color="transparent"
                      hide-details
                      class="c-buscarProduto"
                      @change="novaQueryProduto()"
                      return-object
                    >
                <template v-slot:item="{item}">
                  <v-sheet
                    color="#943634"
                    elevation="3"
                    height="30px"
                    width="100%"
                    class="rounded-lg d-flex flex-row justify-center align-center pa-5 pl-3 my-1 py-6 white--text"
                    >
                    <p class="ma-0 pa-0">{{item.name}}</p>
                    <v-img contain width="20px" height="40px" position="right" :src="item.imageUrl" class="rounded-lg">
                    </v-img>
                  </v-sheet>
                </template>
              </v-autocomplete>
                    <hr class="mb-2 mt-1" />
                    <v-icon color="#943634" left>insert_chart_outlined</v-icon>
                    <span v-if="pregoesAtivos">PREGÕES ATIVOS </span>
                    <span v-else>PREGÕES FINALIZADOS </span>
                    <span class="u-destaque-2">{{insights.totalDePregao}}</span> |
                    <v-icon color="#943634" left>donut_large</v-icon>PARTICIPAÇÃO EM
                    <span class="u-destaque-2">{{insights.totalDeParticipacao}}</span>
                    <span v-if="pregoesAtivos"> PREGÕES ATIVOS </span>
                    <span v-else> PREGÕES FINA.. </span>
                    <br />
                    <v-icon color="#943634" left>request_page</v-icon>VENDIDO
                    <span class="u-destaque-2">{{ insights.totaisVendidos }} Un.</span>
                    | <v-icon color="#943634" left>local_shipping</v-icon>ENTREGUE
                    <span class="u-destaque-2">{{ insights.totaisEntregues }} Un.</span>
                  </p>
                </v-col>

                <v-col cols="12" sm="2" class="
                    d-flex
                    flex-row
                    justify-center
                    align-center
                    flex-nowrap
                    ma-0
                    pa-0
                    pr-3
                    text-right
                    c-pregress
                  ">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <p class="
                          text-caption
                          font-weight-black
                          mb-0
                          u-color u-select
                        ">
                        ENTREGUE
                      </p>
                      <v-icon color="#ACACAC" v-bind="attrs" v-on="on" class="px-1 u-pointer">
                        info
                      </v-icon>
                    </template>
                    <span>{{
                    `${insights.porcetagemDeEntregues}% ENTREGUE DE ${insights.totaisVendidos} VENDIDOS`
                    }}</span>
                  </v-tooltip>
                  <v-progress-circular :rotate="-90" :size="70" :width="8" :value="insights.porcetagemDeEntregues"
                    color="#943634" class="ma-0 pa-0">
                    <p class="mb-0 u-select">
                      {{ insights.porcetagemDeEntregues }}%
                    </p>
                  </v-progress-circular>
                </v-col>
                <v-col cols="12" sm="2" class="
                    d-flex
                    flex-row
                    justify-center
                    align-center
                    flex-nowrap
                    ma-0
                    pa-0
                    pr-3
                    text-right
                    c-pregress
                  ">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <p class="
                          text-caption
                          font-weight-black
                          mb-0
                          u-color u-select
                        ">
                        PARTICIPAÇÃO
                      </p>
                      <v-icon color="#ACACAC" v-bind="attrs" v-on="on" class="px-1 u-pointer">
                        info
                      </v-icon>
                    </template>
                    <span>{{
                    `PARTICIPAÇÃO DE ${insights.porcetagemDePartipacao}% DE ${insights.totalDePregao} PREGÕES`
                    }}</span>
                  </v-tooltip>
                  <v-progress-circular :rotate="-90" :size="70" :width="8" :value="insights.porcetagemDePartipacao"
                    color="#943634" class="ma-0 pa-0">
                    <p class="mb-0 u-select">
                      {{ insights.porcetagemDePartipacao }}%
                    </p>
                  </v-progress-circular>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
          <graficosProdutos v-if="ativarGraficos && 'ValoresDeVenda' in insights" :idProduto="produtoDefinido.id" :valoresDeVenda="insights.ValoresDeVenda"
            :valoresDeCompra="insights.ValoresDeCompra" :quantidadesCadastradas="insights.QuantidadesCadastradas">
          </graficosProdutos>
        </v-row>
      </template>

      <template v-slot:default="props">
        <v-row class="pa-4 pt-0">
          <v-col v-for="(item, idx) in props.items" :key="idx" cols="12" sm="6" md="4" lg="3">
            <v-card>
              <v-card-title dark class="u-bg-2 white--text">
                <span class="text-body-2 pr-1">Pregão:</span>
                <p class="subtitle-1 my-0 py-0 font-weight-bold u-destaque">
                  {{ item.idPregao }}
                <!--   {{ item }} -->
                </p>
              </v-card-title>

              <v-divider></v-divider>

              <v-list class="my-0 py-0" dense>
                <v-list-item v-for="(key, index) in filteredKeys" :key="index" :class="{
                  'u-bg-par': index % 2 == 1 ? true : false,
                  'u-bg-impar': index % 2 == 0 ? true : false,
                  'u-bg-destaque': sortBy === key
                }">
                  <v-list-item-content :class="{ 'u-destaque': sortBy === key }" class="pr-1">{{ Object.values(key)[0]
                  }}:
                  </v-list-item-content>

                  <v-list-item-content class="align-end" :class="{ 'u-destaque': sortBy === key }">
                    <p class="mb-0" v-if="Object.values(key)[0] === 'Data Final'">
                      {{ formatDataFinal(item.dataFinal)}}
                    </p>
                    <p class="mb-0" v-else-if="Object.values(key)[0] === 'PNE'">
                      HS:{{ item[Object.keys(key)].hs }} NI:{{
                      item[Object.keys(key)].ni
                      }}
                    </p>
                    <p class="mb-0" v-else-if="Object.values(key)[0] === 'Valor deCompra'">
                      <v-text-field
                        :key="idx"
                        v-model.number="metricasProdutos[idx].valorDeCompra"
                        :readonly="!editarValorCompraEUnidade" solo flat background-color="transparent"
                        single-line hide-details placeholder="Valor"
                        :append-outer-icon="!editarValorCompraEUnidade ? '': 'save'" type="number"
                        @click:append-outer="editarValorCompra(item, idx)"></v-text-field>
                    </p>
                    <p class="mb-0" v-else-if="Object.values(key)[0] === 'Un. de medida'">
                      <v-select
                        :key="idx"
                        v-model="metricasProdutos[idx].tipoUnidade"
                        dense
                        solo
                        flat
                        :readonly="!editarValorCompraEUnidade" single-line hide-details menu-props="auto"
                        background-color="transparent" :items="selecTipoUnidade" class="pa-0 ma-0"
                        :class="{'c-tipo-unidade':!editarValorCompraEUnidade}"
                      >
                        <template v-slot:selection="{ item }">
                          {{ Object.keys(item)[0] }}
                        </template>
                        <template v-slot:item="{ item }">
                          <v-card width="100%" flat class="ma-0 pa-0" color="white">
                            <v-list dark dense color="#943634" class="ma-0 pa-0">
                              <v-list-item v-for="(valor, idx) in item" :key="idx" class="my-1">
                                <v-list-item-title class="font-weight-bold pa-0 ma-0">{{ valor }} - {{ idx }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </template>
                      </v-select>

                    </p>
                    <p class="mb-0" v-else>{{ item[Object.keys(key)[0]] }}</p>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row class="py-2 px-7" align="center" justify="center">
          <span class="grey--text">Itens por página</span>
          <v-menu offset-y color="#BA4441">
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark text color="#BA4441" class="ml-2" v-bind="attrs" v-on="on">
                {{ itensPorPagina }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index"
                @click="updateItemsPerPage(number)">
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span class="mr-4 grey--text">
            Página {{ pagina }} de {{ numberOfPages }}
          </span>
          <v-btn fab dark color="#BA4441" class="mr-1" @click="formerPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab dark color="#BA4441" class="ml-1" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:no-data>
        <p class="text-center pa-5 ma-2">O produto não está participando de nehum pregão!</p>
      </template>

      <template v-slot:no-results>
        <p class="text-center pa-5 ma-2">Pregão não encontrado!</p>
      </template>
    </v-data-iterator>
  </v-container>
</template>
  
<script>
import axios from "axios";
import { urlApi, showError, userKey } from "@/global";
import graficosProdutos from "./graficosProdutos.vue";
export default {
  name: "MetricasProdutos",
  props: {
    dataProduto: Object,
    todosProdutos: Array,
  },
  components: { graficosProdutos },
  data: () => ({
    pregoesAtivos: true,
    valCompra: 0,
    produtoDefinido: {},
    selecTipoUnidade: [
      { Unidade: "Un" },
      { Litro: "Lt" },
      { Mililitro: "Ml" },
      { Quilo: "Kg" },
      { Grama: "gr" },
      { indefinido: "Não definido" },
    ],
    NovaUnidadeMedida: {},
    editarValorCompraEUnidade: false,
    ativarResumo: true,
    ativarGraficos: true,
    insights: {},
    itemsPerPageArray: [100, 200, 300, 400, 500, 1000],
    filter: {},
    sortDesc: false,
    sortBy: "dataFinal",
    search: "",
    itensPorPagina: 100,
    pagina: 1,
    metricasProdutos: [],
    keys: [
      { dataFinal: "Data Final" },
      { pne: "PNE" },
      { ValordeVenda: "Valor de Venda" },
      { valorDeCompra: "Valor deCompra" },
      { quantCadastrada: "Quant. Cadastrada" },
      { quantRestante: "Quant. Restante" },
      { tipoUnidade: "Un. de medida" },
    ],
  }),
  computed: {
    formatDataFinal() {
      return (dataFinal) => {
        let dataDia = new Date(dataFinal).toLocaleString("pt-BR", {
          day: "numeric",
        });
        let dataMes = new Date(dataFinal).toLocaleString("pt-BR", {
          month: "numeric",
        });
        let dataAno = new Date(dataFinal).toLocaleString("pt-BR", {
          year: "numeric",
        });
        return `${dataDia}/${dataMes}/${dataAno}`;
      };
    },
    numberOfPages() {
      return Math.ceil(
        this.metricasProdutos.length / this.itensPorPagina
      );
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Número Pregão");
    },
  },
  watch: {
    pregoesAtivos(novaOp){
      this.produtoDefinido.pregoesAtivos = novaOp
      this.getMetricas(this.produtoDefinido);
    },
    "dataProduto.id": {
      handler(novaQuery, antigaQuery) {
        if (novaQuery !== antigaQuery) {
          this.produtoDefinido = Object.assign({}, this.dataProduto)
          this.produtoDefinido.pregoesAtivos = this.pregoesAtivos
          this.getMetricas(this.produtoDefinido);
        }
      },
      immediate: true,
    },
  },
  methods: {
    fecharMetricas(){
      this.$emit('fecharMetricas')
    },
    novaQueryProduto(){
      this.NovaUnidadeMedida = {}
      this.produtoDefinido.pregoesAtivos = this.pregoesAtivos
      this.getMetricas(this.produtoDefinido)
    },
    salvarDefinicoes() {
      let key = "definicoesTelaProdutos";
      let definicoes = {
        resumo: this.ativarResumo,
        grafico: this.ativarGraficos,
      };
      localStorage.setItem(key, JSON.stringify(definicoes));
    },
    editarValorCompra(consulta, id) {
      let queryProduto = {
        name: consulta.name,
        idPregao: consulta.idPregao,
        refPregao: consulta.refPregao,
        refEmpenho: consulta.refEmpenho,
        isEmpenho: consulta.isEmpenho,
        valorDeCompra: consulta.valorDeCompra,
        tipoUnidade:
          Object.keys(consulta.tipoUnidade)[0] === "indefinido"
            ? null
            : consulta.tipoUnidade,
      };
      axios
        .post(`${urlApi}/editarValorCompra`, queryProduto)
        .then(_ => {
          this.$toasted.global.defaultSuccess();
        })
        .catch(showError);
    },
    ordenarMes(dado) {
      return dado.sort((a, b) => {
        return new Date(a.mes).getTime() - new Date(b.mes).getTime();
      });
    },
    getMetricas(queryProduto) {
      axios
        .post(`${urlApi}/getMetricas`, queryProduto)
        .then((res) => {
          if(res.data.length !== 0){
            this.insights.QuantidadesCadastradas = this.ordenarMes(
            res.data[0].insightsParaGraficos.QuantidadesCadastradas
          );

          this.insights.ValoresDeCompra = this.ordenarMes(
            res.data[0].insightsParaGraficos.ValoresDeCompra
          );

          this.insights.ValoresDeVenda = this.ordenarMes(
            res.data[0].insightsParaGraficos.ValoresDeVenda
          );

          this.insights.porcetagemDePartipacao =
            res.data[0].porcetagemDePartipacao;

          this.insights.totalDeParticipacao =
            res.data[0].totalDeParticipacao;
          this.insights.totalDePregao = res.data[0].totalDePregao;
          this.insights.totaisVendidos =
            res.data[0].totaisVendidos.toLocaleString("pt-BR", {
              style: "decimal",
              currency: "BRL",
            });
          this.insights.totaisEntregues =
            res.data[0].totaisEntregues.toLocaleString("pt-BR", {
              style: "decimal",
              currency: "BRL",
            });
          this.insights.id = res.data[0].id;
          this.insights.porcetagemDeEntregues =
            res.data[0].porcetagemDeEntregues;

          /* delete res.data[0].totaisVendidos;
delete res.data[0].totaisEntregues;
delete res.data[0].porcetagemDePartipacao;
delete res.data[0].totalDeParticipacao;
delete res.data[0].totalDePregao;
delete res.data[0].insightsParaGraficos; */

          this.metricasProdutos = res.data;
          }
          else{
            this.metricasProdutos = []
            this.insights = {
              totaisVendidos: 0,
              totalDePregao: '-',
              totalDeParticipacao: 0,
              totaisEntregues: 0,
              porcetagemDeEntregues: 0,
              insightsParaGraficos: {}
            }
          }
           const getDefinicao = JSON.parse(
            localStorage.getItem("definicoesTelaProdutos")
          );
          if (!!getDefinicao) {
            this.ativarResumo = getDefinicao.resumo;
            this.ativarGraficos = getDefinicao.grafico;
          }
        })
        .catch(showError);
    },
    nextPage() {
      if (this.pagina + 1 <= this.numberOfPages) this.pagina += 1;
    },
    formerPage() {
      if (this.pagina - 1 >= 1) this.pagina -= 1;
    },
    updateItemsPerPage(number) {
      this.itensPorPagina = number;
    },
  },
  created() { },
  beforeMount() { },
  mounted() { },
};
</script>
  
<style>
.c-pregress .v-progress-circular {
  min-width: 70px !important;
}
.u-bg-1 {
  background: #ba4441;
}
.u-bg-2 {
  background: #ba4441;
}
.u-bg-3 {
  background: #d1d1d1;
}
.c-select-ordem .v-input__control,
.v-text-field--solo-inverted.v-input--is-focused>.v-input__control>.v-input__slot {
  background: #b36563 !important;
}
.u-destaque-select {
  color: #f5f5f5;
  font-weight: 500;
}
.u-destaque {
  color: #f5f5f5;
  font-weight: 900;
}
.u-bg-impar {
  background: #e8e8e8;
  margin: 2px 0px !important;
}
.u-bg-par {
  background: #d1d1d1;
  margin: 2px 0px !important;
}
.u-destaque-2 {
  background: #e8e8e8;
  color: #823634;
  font-weight: 900;
  padding: 2px 7px;
  border-radius: 3px;
  box-shadow: 0px 0px 3px 1px rgb(182 182 182 / 76%);
}
.u-bg-destaque {
  background: #4441a3;
}
.c-resumo-simples {
  display: flex;
  flex-flow: row nowrap;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.c-tipo-unidade .v-input__icon {
  display: none;
}
.c-tipo-unidade {
  pointer-events: none;
}
.c-buscarProduto input {
    color: #823634!important;
    padding-left: 0px!important;
    margin-left: -11px!important;
    font-weight: 900px;
}
</style>