<template>
  <v-dialog persistent max-width="95%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab dark large class="mx-2" elevation="4"  color="primary" v-bind="attrs" v-on="on">
        <v-icon large>widgets</v-icon></v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card>
           <v-toolbar color="primary" dark>
          <v-toolbar-title>
          <v-toolbar-items>Cadastrar Produto</v-toolbar-items>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn class="mx-2" icon>
              <v-icon dark large @click="dialog.value = false">cancel</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
       
        <cadastroProduto />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import cadastroProduto from "./cadastroProduto";
export default {
  name: "modalCadastroProduto",
  components: { cadastroProduto },
};
</script>
<style>
</style>