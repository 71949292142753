<template>
  <v-data-table
    :headers="headers"
    :items="infUsuarios = infUsuarios"
    item-key="id"
    sort-by="ID"
    class="mt-5"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn absolute right class="mx-2" fab dark color="primary" elevation="9" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Novo Usuário</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                    type="text"
                    name="usuario"
                    outlined v-model="userNovo.name" hint="Digite o nome do usuário" label="Nome do Usuário"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        outlined
                        type="email"
                        name="email"
                        append-icon="email"
                        :rules="[rules.required, rules.email]"
                        hint="Digite o e-mail do usuário"
                        label="E-mail do Usuário"
                        v-model="userNovo.email"
                        >
                        </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        outlined
                        :append-icon="trocarIconeLogin ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="trocarIconeLogin ? 'text' : 'password'"
                        name="password"
                        label="Senha do Usuário"
                        class="input-group--focused"
                        @click:append="trocarIconeLogin = !trocarIconeLogin"
                        v-model="userNovo.password">
                    </v-text-field>
                  </v-col>

                   <v-col cols="12" sm="6" md="6">
                       <v-text-field
                         outlined
                        :append-icon="trocarIconeLogin ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.emailMatch]"
                        :type="trocarIconeLogin ? 'text' : 'password'"
                        name="confirmPassword"
                        label="Cofirmação de Senha"
                        hint="Confirme a senha do usuário"
                        class="input-group--focused"
                        @click:append="trocarIconeLogin = !trocarIconeLogin"
                        v-model="userNovo.confirmPassword">
                    </v-text-field>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="btn-cancelar" color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="sendUsuario">Salvar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Tem certeza de que deseja excluir este usuário?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogUsuarioAtual" max-width="500px">
          <v-card>
              <v-col class="d-flex justify-center" cols="12" sm="12" md="12"> 
              <v-alert dense outlined type="error">
                 O usuário atual não pode ser excluído!
              </v-alert>
              </v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDialogoErro">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
      <h2 class="u-text-col-user mb-2" >Usuários Cadastrados</h2>
    </template>
     <template #item.admin="{item}">
      <v-text-field
        class="mt-5"
        single-line
        dense
        :value="item.admin ? 'Sim': 'Não'">
     </v-text-field>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="text-center d-flex justify-end">
      <v-btn class="mx-2" fab dark x-small color="primary">
      <v-icon dark @click="deleteItem(item)">mdi-delete</v-icon>
      </v-btn></div>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";
import barramento from "@/barramento"
import { urlApi, showError } from "@/global"
export default {
  name: "usuarios",
  props:{
      infUsuarios: Array,
      usuarioAtualNaTabela: Object,
  },
  data: function() {
    return {
      search: "",
      dialog: false,
      dialogDelete: false,
      dialogUsuarioAtual: false,
      headers: [
        { text: "id", value: "id" },
        { text: "Nome", value: "name" },
        { text: "E-mail", value: "email" },
        { text: "ADM?", width: '100', value: "admin"},
        { text: "Ação", align: 'end', value: "actions", sortable: false }
      ],

      trocarIconeLogin: false,
      rules: {
            required: value => !!value || 'Campo obrigatório.',
            min: v => {
                if(v == undefined){
                  return 'Digite uma senha'
                    }else{
                      return v.length >= 8 || 'Min de 8 caracteres'
                    }
                },
            emailMatch: val => val == this.userNovo.password || 'Senhas não coincidem',
            email: value => {
                  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  return pattern.test(value) || 'Email inválido.'
                  }
          },

      users: this.infUsuarios,
      editedIndex: -1,
      delUsuario: {},
      editedItem: {},
      editeUserAtual: this.usuarioAtual,
      userNovo: {
          admin: true
      },
      defaultItem: {},
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  methods: {
    salve(usuarioNovo) {
        this.userNovo.admin = true
      axios.post(`${urlApi}/users`, usuarioNovo)
      .then(() => {
          this.userNovo = {}
          barramento.enviarNovosUsuarios(true)
          this.$toasted.global.defaultSuccess()})
      .catch(showError)
    },

    deleteItem(item) {
        if(this.usuarioAtualNaTabela.email == item.email){
            this.dialogUsuarioAtual = true
        }else{
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
        }
    },

    deleteItemConfirm() {
      axios
      .delete(`${urlApi}/users/${this.editedItem.id}`, this.editedItem)
      .then(() => {
          this.users.splice(this.editedIndex, 1)
          barramento.enviarNovosUsuarios(true)
          this.$toasted.global.defaultSuccess()
          })
      .catch(showError)
      this.closeDelete()
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
     
     closeDialogoErro(){
          this.dialogUsuarioAtual = false
      },

    sendUsuario() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem)
      } else {
        this.users.push(this.editedItem)
      }
      this.editedItem.admin = true
      this.salve(this.userNovo)
      this.close()
    }
  },
};
</script>

<style>
.c-usuario__item_admin{
    width: 60px;
}
.u-text-col-user {
    color: #666666;
}
</style>