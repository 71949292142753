<template>
  <v-data-table
    :items-per-page="totalPageEmpenhoStart"
    :headers="headers"
    :items="listaProdutosEmpenho = listaProdutosPregao.listaProdutos"
    item-key="idUnico"
    sort-by="Produto"
    class="mt-2 c-table-empenho"
    :loading="loading"
    :search="search"
    hide-default-header
  >
    <template v-slot:top>
      <v-toolbar flat prominent class="mb-5">
        <v-row class="justify-center align-center">
       
        <v-col class="mb-0 pb-0 " cols="12" sm="12" md="4">
        <!-- Calcular valor do saldo do pregao -->
         <div class="u-hide"> {{calcSaldoPregao('valorTotal')}} </div>
        <!--  Calcular valor total empenho -->
         <div class="u-hide"> {{calcValorEmpenho('valorItemEmpenho')}} </div>
        <v-text-field
          outlined
          readonly
          label="Saldo do Pregão"
          prefix="Saldo:"
          :value="valorTotalGeral | dinheiro">
        </v-text-field>
        </v-col>

        <v-col class="mb-0 pb-0 " cols="12" sm="12" md="4">
        <v-text-field
          class="ml-3"
          outlined
          readonly
          label="Valor do Empenho"
          prefix="Atual:"
          :value="valorTotalEmpenho | dinheiro">
        </v-text-field>
        </v-col>

        <v-col class="mb-0 pb-0 " cols="12" sm="12" md="5">
        <v-text-field
          hint="Busque Por: Nome do Produto"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar produto"
          single-line
        ></v-text-field>
        </v-col>

        </v-row>
        <v-dialog v-model="dialogEditarValoresStart" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col class="mb-0 pb-0 " cols="12" sm="12" md="6">
                    <v-text-field disabled :value="editedItem.name" outlined></v-text-field>
                  </v-col>

                    <v-col class="mb-0 pb-0 " cols="12" sm="12" md="3">
                    <v-text-field label="Quantidade Restante"  disabled type="number" v-model.number="editedItem.quantidadeProdutoPregao" outlined></v-text-field>
                  </v-col><v-spacer md="5"></v-spacer>

                  <v-col cols="12" sm="12" md="4">
                    <v-text-field type="number" disabled outlined v-model="editedItem.idGenericaProduto" label="ID do Item"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <v-text-field class="u-clear-data" :rules="rulesQuantidade" outlined type="number" v-model.number="editedItem.quantEmpenho" label="Adicionar Quantidade"></v-text-field>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="btn-cancelar" color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn v-if="editedItem.quantEmpenho > editedItem.quantidadeProdutoPregao" color="blue darken-1" text @click="salveListaProdutosEmpenhoStart" disabled>Salvar</v-btn>
              <v-btn v-else color="blue darken-1" text @click="salveListaProdutosEmpenhoStart">Salvar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
     <!-- Dados da tabela -->
    <template #item.imageUrl="{item}">
      <img position="right right" class="mt-3 mb-0 rounded-lg elevation-1" :src="item.imageUrl" alt="Imagem" width="70px" height="auto" /><br />
      <p class="c-table__nome ma-0 pa-0">{{item.name}}</p>
      <p class="c-table__nome">Item: {{item.idGenericaProduto}} | {{ item.tipoUnidade != null ? Object.values(item.tipoUnidade)[0] : "-"}}</p>
    </template>

    <template #item.valorDeCompra="{ item }">
      <v-currency-field
        readonly
        class="c-table-empenho__item"
        prefix="R$"
        :value="item.valorDeCompra"
        hint="VALOR DE COMPRA"
        persistent-hint
        disabled
      />
    </template>

    <template #item.valorProdutoPregao="{item}">
      <v-text-field
      class="c-table-empenho__item"
      hint="VALOR DE VENDA NO PREGAO"
      persistent-hint
      disabled
      :value="item.valorProdutoPregao | dinheiroDecimal">
      </v-text-field>
    </template>

    <template #item.quantidadeProdutoPregao="{item}">
      <v-text-field
      class="c-table-empenho__item"
      disabled
      type="number"
      v-model.number="item.quantidadeProdutoPregao"
      hint="QUANTIDADE CADASTRADA"
      persistent-hint></v-text-field>
    </template>
     <template #item.valorTotal="{item}">
      <v-text-field
      class="c-table-empenho__item"
      disabled
      :value="item.valorTotal | dinheiroDecimal"
      hint="VALOR TOTAL CADASTRADO"
      persistent-hint>
      </v-text-field>
    </template>

    <template #item.quantRestante="{item}">
      <div class="u-hide">{{item.quantRestante = item.quantidadeProdutoPregao - item.quantEmpenho}} - {{item.proxEmpenho = item.quantRestante}}</div>
      <v-text-field
      class="c-table-empenho__item"
      disabled
      type="number"
      v-model.number="item.quantRestante"
      hint="QUANTIDADE RESTANTE"
      persistent-hint></v-text-field>
    </template>
    
     <template #item.quantEmpenho="{item}">
      <v-text-field
      color="#943634"
      readonly
      class="u-clear-data c-table-empenho__item"
      type="number"
      v-model.number="item.quantEmpenho"
      hint="QUANTIDADE DO EMPENHO"
      persistent-hint></v-text-field>
    </template>

    <template #item.valorItemEmpenho="{item}">
      <div class="u-hide">{{item.valorItemEmpenho = item.quantEmpenho * item.valorProdutoPregao }}</div>
      <v-text-field
      class="u-clear-data c-table-empenho__item"
      hint="VALOR DO EMPENHO"
      persistent-hint
      disabled
      :value="item.valorItemEmpenho | dinheiro"></v-text-field>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="text-center d-flex ">
      <v-btn class="mx-2" fab dark x-small color="#943634">
        <v-icon dark @click="editValoresEmpenho(item)">mdi-pencil</v-icon>
      </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";
import { urlApi } from "@/global";
import barramento from "@/barramento"

export default {
  name: "ListaProdutosEmpenho",
  props:{
    listaProdutosPregao: {}
  },
  data: function() {
    return {
      loading: true,
      search: "",
      dialogEditarValoresStart: false,

      headers: [
        { text: "Imagem", align: "start", sortable: false, value: "imageUrl" },
        { class: "u-hide", cellClass: "u-hide", text: "Produto", value: "name" },
        { class: "u-hide", cellClass: "u-hide", text: "N°Item", value: "idGenericaProduto" },
        { class: "u-hide", cellClass: "u-hide", text: "Un. de Medida", align: "center", value: "tipoUnidade" },
        { text: "Valor de Compra", value: "valorDeCompra" },
        { text: "Valor do Item Cadastrado", value: "valorProdutoPregao" },
        { text: "Quantidade Cadastrada", value: "quantidadeProdutoPregao"},
        { text: "Valor Total Cadastrado", value: "valorTotal"},
        { text: "Quantidade Restante", value: "quantRestante"},
        { text: "Quantidade do Empenho", value: "quantEmpenho"},
        { text: "Quantidade Restante", value: "valorItemEmpenho"},
        { text: "Ação", value: "actions", sortable: false }
      ],

      valorTotalGeral: 0,
      valorTotalEmpenho: 0,
      valorTotalGeralEntregue: 0,
      listaProdutosEmpenho: this.listaProdutosPregao.listaProdutos,
      sendProdutosEmpenho: [],
      editedIndex: -1,
      delProduto: {},
      editedItem: {},
      defaultItem: {},

      rulesQuantidade:[
        value => value <= this.editedItem.quantidadeProdutoPregao || 'Quantidade superior ao saldo',
      ],
      totalPageEmpenhoStart: -1,
   
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Adicionar Produto" : "Add Quantidade deste Empenho";
    }
  },

  watch: {
    dialogEditarValoresStart(val) {
      val || this.close();
    },
  },

  methods: {
    calcSaldoPregao(chave) {
       this.valorTotalGeral =  this.listaProdutosEmpenho.reduce((a, b) => a + (b[chave] || 0), 0)
    },

    calcValorEmpenho(chave) {
       this.valorTotalEmpenho =  this.listaProdutosEmpenho.reduce((a, b) => a + (b[chave] || 0), 0)
       barramento.EnviarTotalEmpenho(this.valorTotalEmpenho)
    },

    editValoresEmpenho(item) {
        this.editedIndex = this.listaProdutosEmpenho.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogEditarValoresStart = true;
        },

    close() {
        this.dialogEditarValoresStart = false;
        this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
            });
        },

   salveListaProdutosEmpenhoStart() {

        if (this.editedIndex > -1) {
              
              Object.assign(this.listaProdutosEmpenho[this.editedIndex], this.editedItem)
              
              this.listaProdutosEmpenho[this.editedIndex].quantParaNota = this.listaProdutosEmpenho[this.editedIndex].quantEmpenho 

              this.listaProdutosEmpenho[this.editedIndex].valorItemEmpenhoGeral = this.listaProdutosEmpenho[this.editedIndex].valorItemEmpenho
              this.listaProdutosEmpenho[this.editedIndex].emitidaNaNota = 0 

              }  

        barramento.EnviarProdutosEmpenho(this.listaProdutosEmpenho, true)
        this.close();
    },

  },
mounted(){
   if(this.listaProdutosPregao) this.loading = false;
 },

};
</script>
    
<style>
.textarea-nome{
  width: 90px;
}
.textarea-nome .v-input__slot:before {
    content: "";
    display: none;
}
.textarea-nome .v-text-field__details{
    display: none;
}

.textarea-nome textarea {
    line-height: 14px!important;
}
.c-table-empenho__item {
    width: 90px;
}
.c-table-empenho .v-messages__message {
    font-size: x-small;
    font-weight: 700;
   
}
</style>