import Vue from 'vue'

export const userKey = '__controlePregao_user'
export const urlApi = 'https://pregaov2-production-20c7.up.railway.app'
/* export const urlApi = 'http://localhost:1020' */

export function showError(e, msgPersonalizada) {
    if(e && e.response && e.response.data) {
        Vue.toasted.global.defaultError({ msg : e.response.data })
    } else if(typeof e === 'string') {
        Vue.toasted.global.defaultError({ msg : e } + msgPersonalizada)
    } else {
        Vue.toasted.global.defaultError()
    }
}

export default { urlApi, showError }