<template>
  <v-dialog
      persistent
      v-model="dialogCadasTroPregao"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab dark large class="mx-2" elevation="4"  color="primary" v-bind="attrs" v-on="on">
        <v-icon large>add_chart</v-icon></v-btn>
    </template>
    <template v-slot:default="dialogCadasTroPregao">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Cadastrar Pregão</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn  class="mx-2" icon>
              <v-icon dark large
              @click="dialogCadasTroPregao.value = false">
                cancel
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <cadastroPregao :orgaosCadastrados="orgaosCadastrados" :produtosCadastrados="produtosCadastrados"/>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn id="js-modal-pregao__close" text @click="dialogCadasTroPregao.value = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import cadastroPregao from './cadastroPregao'
import axios from "axios";
import { urlApi, showError } from "@/global";
export default {
  name: "modalCadastroPregao",
  components: { cadastroPregao},

  data: function() {
    return { 
      dialogCadasTroPregao: false,   
      orgaosCadastrados: [],
      produtosCadastrados: [],
      }
    },
    watch:{
      dialogCadasTroPregao(val){
        if(val) this.getOrgao()
        this.getProduto()
      }
    },
    methods:{
        fecharModal(){
          this.dialogCadasTroPregao = false
      },
      getOrgao() {
        axios.get(`${urlApi}/orgao`)
        .then(res => { res.data.filter((orgao, index, arr) => {
        this.orgaosCadastrados.push(arr[index].nomeOrgao)})
        })
        .catch(showError)
    },
    getProduto() {
      axios.get(`${urlApi}/produto`)
      .then(res => {this.produtosCadastrados = res.data})
      .catch(showError)
    },
    }
};
</script>

<style>
</style>