<template>
  <v-form>
    <v-row class="d-flex space-between">
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          outlined
          cols="12"
          sm="12"
          md="4"
          hint="Dados do recibo"
          v-model="dadosDaNota.notaRecibo">
          <template class="m-p-4" v-slot:label>
            <strong>NOTA/RECIBO</strong>
            <v-icon style="vertical-align: middle">description</v-icon>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <v-menu
          ref="menuRecibo"
          v-model="menuRecibo"
          :close-on-content-click="false"
          transition="scale-transition"
          origin="center center"
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="dadosDaNota.dataRecibo"
              label="DATA DA NOTA"
              hint="Data do recibo"
              persistent-hint
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dadosDaNota.dataRecibo"
            :active-picker.sync="activePicker2"
            min="1950-01-01"
            @change="dateSaveRecibo"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <v-btn block x-large color="#363494" @click="criarNota" v-on:dblclick.prevent>
          <v-icon color="#fff" left>mdi-clipboard-check-outline</v-icon><span style="color:White;">GERAR DADOS DA NF</span>
        </v-btn>
      </v-col>

         <v-col cols="12" sm="12" md="12">
           <VueEditor placeholder="Observações da nota" v-model="dadosDaNota.observacoes" />
         </v-col>

       <v-col v-if="isNota" cols="12" sm="12" md="12"> 
       <listaProdutosNota
       :somaValorTotalNotasEmitidas="somaValorTotalNotasEmitidas"
       :listaNotas="listaNotas"
       :itemEmpenho="itemEmpenho" />
      </v-col>

       <v-col v-if="!isNota" cols="12" sm="12" md="12"> 
       <listaProdutosNotaStart v-if="!isNota"
       :itemFiltrado="itemFiltrado"
       :itemEmpenho="itemEmpenho" />
      </v-col>

    </v-row>
  </v-form>
</template>
<script>
import listaProdutosNota from "./listaProdutosNota";
import listaProdutosNotaStart from "./listaProdutosNotaStart";
import axios from "axios";
import { urlApi, showError } from "@/global";
import barramento from "@/barramento";
import { VueEditor } from "vue2-editor"
export default {
  name: "gerarNota",
  props:{
    itemEmpenho: {},
    listaNotas: [],
    itemFiltrado: [],
    isNota: [Boolean, Function],
    primeiroEmpenho: [Boolean, Function],
    somaValorTotalNotasEmitidas: [Number, String],
  },
  components: { listaProdutosNota, listaProdutosNotaStart, VueEditor },

  data: () => ({
    dadosDaNota: {},
    listaProdutosNf: [],
    bdNotas: [],
    valorNota: 0,
    activePicker: null,
    activePicker2: null,
    menu: false,
    menuRecibo: false,
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "Ano"))
    },
    menuRecibo(val) {
      val && setTimeout(() => (this.activePicker2 = "Ano"))
    },
  },
  methods: {
    limparForm(){
      this.dadosDaNota = {}
        document.querySelectorAll('.c-table-nota .u-clear-data input').forEach(quantidade => quantidade.value = 0)
    },

    dateSave(date) {
      this.$refs.menu.save(date);
    },
    dateSaveRecibo(date) {
      this.$refs.menuRecibo.save(date);
    },
    criarNota() {

      this.bdNotas = this.listaProdutosNf.filter(produto => produto !== undefined )
      this.dadosDaNota.idEmpenho = this.itemEmpenho.id
      this.dadosDaNota.idRefPregao = this.itemEmpenho.idRefPregao
      this.dadosDaNota.nomeOrgao = this.itemEmpenho.nomeOrgao
      this.dadosDaNota.saldoEmpenho = this.itemEmpenho.valorTotalEmpenho
      this.dadosDaNota.valorNf = this.valorNota
      this.dadosDaNota.numeroNf = '0000'

      this.dadosDaNota.listaProdutosNf = JSON.stringify(this.bdNotas)
        axios
        .post(`${urlApi}/nota`, this.dadosDaNota)
        .then(res => {
          barramento.EnviarNotas(true, this.itemEmpenho.id)
          this.limparForm()
          document.getElementById("js-modal-nota__close").click()
          this.$toasted.global.defaultSuccess()
          })
        .catch(showError)
    },
  },

  created() {
    barramento.getProdutosNota(listraProdutosNf => {
        this.listaProdutosNf = listraProdutosNf
    })
    barramento.getTotalNota(totalNota => {
      this.valorNota = totalNota
    })
  },

};
</script>

<style>
.form-pregao__item {
  font-family: "Georama", sans-serif !important;
}
</style>