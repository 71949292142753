<template>
  <v-data-table
    :headers="headers"
    :items="infOrgao"
    item-key="id"
    sort-by="ID"
    class="mt-5"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar Órgao"
          single-line
          hide-details
        ></v-text-field>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-2" fab dark color="primary" elevation="9" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="7" md="7">
                    <v-text-field v-model="editedItem.nomeOrgao" hint="Digite o nome do órgão" label="Nome do Órgão"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="btn-cancelar" color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="send">Salvar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Tem certeza de que deseja excluir este item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="text-center d-flex justify-end">
      <v-btn class="mx-2" fab dark x-small color="primary">
      <v-icon dark @click="editItem(item)">mdi-pencil</v-icon>
      </v-btn>
      <v-btn class="mx-2" fab dark x-small color="primary">
      <v-icon dark @click="deleteItem(item)">mdi-delete</v-icon>
      </v-btn></div>    
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";
import { urlApi, showError } from "@/global";
export default {
  name: "cadastrOrgao",
  data: function() {
    return {
      search: "",
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Nome do Órgão", value: "nomeOrgao" },
        { text: "Ação", align: 'end', class:"acaoOrgao", value: "actions", sortable: false }
      ],

      infOrgao: [],
      editedIndex: -1,
      delOrgao: {},
      editedItem: {},
      defaultItem: {},
      orgao: {}
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Órgão" : "Editar Nome";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  methods: {
    salve(orgao) {
      axios.post(`${urlApi}/orgao`, orgao)
      .then(() => this.$toasted.global.defaultSuccess())
      .catch(showError)
    },
    getOrgao() {
      axios.get(`${urlApi}/orgao`)
      .then(res => {this.infOrgao = res.data
      })
      .catch(showError)
    },

    remover(orgao) {
      axios
        .delete(`${urlApi}/orgao`, orgao)
        .then(() => this.$toasted.global.defaultSuccess())
        .catch(showError)
    },

    editItem(item) {
      this.editedIndex = this.infOrgao.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.infOrgao.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.delOrgao = {...this.editedItem};
    },

    deleteItemConfirm() {
      this.infOrgao.splice(this.editedIndex, 1);
      axios.delete(`${urlApi}/orgao/${this.delOrgao.id}`, this.delOrgao)
      .then(() => this.$toasted.global.defaultSuccess())
      .catch(showError)
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    send() {
      if (this.editedIndex > -1) {
        Object.assign(this.infOrgao[this.editedIndex], this.editedItem);
      } else {
        this.infOrgao.push(this.editedItem);
      }
      this.salve(this.editedItem);
      this.close();
    }
  },
  mounted() {
    this.getOrgao();
  },
  updated(){
    this.getOrgao();
  }
};
</script>

<style>
.acaoOrgao {
    padding-right: 52px!important;
}
</style>