import Vue from 'vue'
export default new Vue({
    methods: {
        enviarNovosUsuarios(novosUser){
            this.$emit('getNovosUsuarios', novosUser)
        },
        getNovosUsuarios(callback){
            this.$on('getNovosUsuarios', callback)
        },
        enviarStatusLogin(isLogin, isSessaoExpirada){
            this.$emit('getStatusLogin', isLogin, isSessaoExpirada)
        },
        getStatusLogin(callback){
            this.$on('getStatusLogin', callback)
        },      
        enviadoProdutosPregao(produtos){
            this.$emit('getTodosprodutosPregao', produtos)
        },
        getProdutosPregao(callback){
            this.$on('getTodosprodutosPregao', callback)
        },
        enviadoValorTotalPregao(produtos){
            this.$emit('getValorTotalPregao', produtos)
        },
        getValorTotalPregao(callback){
            this.$on('getValorTotalPregao', callback)
        },
        enviadoQuantTotalPregao(produtos){
            this.$emit('getQuantTotalPregao', produtos)
        },
        getQuantTotalPregao(callback){
            this.$on('getQuantTotalPregao', callback)
        },
        enviaSaldoEntreguePregao(key, valorEntregue){
            this.$emit('getSaldoEntreguePregao', key, valorEntregue)
        },
        getSaldoEntreguePregao(callback){
            this.$on('getSaldoEntreguePregao', callback)
        },
        enviadoIdPregao(key){
            this.$emit('getIdPregao', key)
        },
        getIdPregao(callback){
            this.$on('getIdPregao', callback)
        },
        enviarBusca(busca){
            this.$emit('getBusca', busca)
        },
        getBusca(callback){
            this.$on('getBusca', callback)
        },
        lista(envlista){
            this.$emit('getPpregao', envlista)
        },
        getPpregao(callback){
            this.$on('getPpregao', callback)
        },
        EnviarLoadPregao(isTrue){
            this.$emit('getLoadPregao', isTrue)
        },
        getLoadPregao(callback){
            this.$on('getLoadPregao', callback)
        },
        EnviarLoadEmpenho(isTrue, id){
            this.$emit('getLoadEmpenho', isTrue, id)
        },
        getLoadEmpenho(callback){
            this.$on('getLoadEmpenho', callback)
        },
        EnviarProdutosEmpenho(produtos, isPrimeiro){
            this.$emit('getProdutosEmpenho', produtos, isPrimeiro)
        },
        getProdutosEmpenho(callback){
            this.$on('getProdutosEmpenho', callback)
        },
        EnviarTotalEmpenho(valorTotal){
            this.$emit('getTotalEmpenho', valorTotal)
        },
        getTotalEmpenho(callback){
            this.$on('getTotalEmpenho', callback)
        },
        EnviarValorGeralEmpenhos(valoes){
            this.$emit('getValorGeralEmpenhos', valoes)
        },
        getValorGeralEmpenhos(callback){
            this.$on('getValorGeralEmpenhos', callback)
        },
        EnviarIsTrueListaEmpenho(isTrue){
            this.$emit('getIsTrueListaEmpenho', isTrue)
        },
        getIsTrueListaEmpenho(callback){
            this.$on('getIsTrueListaEmpenho', callback)
        },
        EnviarNotas(isTrue, id){
            this.$emit('getNotas', isTrue, id)
        },
        getNotas(callback){
            this.$on('getNotas', callback)
        },
        EnviarTotalNota(valorTotalNota){
            this.$emit('getTotalNota', valorTotalNota)
        },
        getTotalNota(callback){
            this.$on('getTotalNota', callback)
        },
        EnviarProdutosNota(produtos){
            this.$emit('getProdutosNota', produtos)
        },
        getProdutosNota(callback){
            this.$on('getProdutosNota', callback)
        },
        EnviarEventDialogoNfFechado(isTrue){
            this.$emit('getEventDialogoNfFechado', isTrue)
        },
        getEventDialogoNfFechado(callback){
            this.$on('getEventDialogoNfFechado', callback)
        },

    }
})