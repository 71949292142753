var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"mt-5 c-table-pregao",attrs:{"headers":_vm.headers,"items":(_vm.infoProdutoPregao = _vm.produtosCadastrados),"item-key":"id","sort-by":"Produto","loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"hint":"Busque Por: Nome","append-icon":"mdi-magnify","label":"Buscar produto","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-currency-field',{attrs:{"outlined":"","readonly":"","label":"Valor do Pregão","prefix":"Saldo: R$","value":_vm.valorTotalGeral}}),_c('div',{staticClass:"calcHide"},[_vm._v(" "+_vm._s(_vm.totalGeralValor("valorTotal"))+" ")]),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialogCadastroPregaoProdutos),callback:function ($$v) {_vm.dialogCadastroPregaoProdutos=$$v},expression:"dialogCadastroPregaoProdutos"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"disabled":"","value":_vm.editedItem.name,"outlined":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"type":"number","outlined":"","label":"N°"},model:{value:(_vm.editedItem.idGenericaProduto),callback:function ($$v) {_vm.$set(_vm.editedItem, "idGenericaProduto", $$v)},expression:"editedItem.idGenericaProduto"}})],1),_c('v-col',{staticClass:"c-select-un-medida",attrs:{"cols":"12","sm":"12","md":"9"}},[_c('v-select',{attrs:{"menu-props":"auto","outlined":"","color":"primary","background-color":"","items":_vm.UnidadeMedida,"label":"Unidade de Medida"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Object.values(item)[0])+" - "+_vm._s(Object.keys(item)[0])+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"ma-0 pa-0",attrs:{"width":"100%","flat":"","color":"primary"}},[_c('v-list',{staticClass:"ma-0 pa-0",attrs:{"dark":"","dense":"","color":"primary"}},_vm._l((item),function(valor,idx){return _c('v-list-item',{key:idx},[_c('v-list-item-title',{staticClass:"font-weight-bold pa-0 ma-0"},[_vm._v(_vm._s(valor)+" - "+_vm._s(idx))])],1)}),1)],1)]}}]),model:{value:(_vm.editedItem.tipoUnidade),callback:function ($$v) {_vm.$set(_vm.editedItem, "tipoUnidade", $$v)},expression:"editedItem.tipoUnidade"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-currency-field',{attrs:{"outlined":"","label":"Valor de Compra"},model:{value:(_vm.editedItem.valorDeCompra),callback:function ($$v) {_vm.$set(_vm.editedItem, "valorDeCompra", $$v)},expression:"editedItem.valorDeCompra"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-currency-field',{attrs:{"outlined":"","label":"Valor de Venda"},model:{value:(_vm.editedItem.valorProdutoPregao),callback:function ($$v) {_vm.$set(_vm.editedItem, "valorProdutoPregao", $$v)},expression:"editedItem.valorProdutoPregao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"7","md":"4"}},[_c('v-text-field',{attrs:{"type":"number","outlined":"","label":"Adicionar Quantidade"},model:{value:(_vm.editedItem.quantidadeProdutoPregao),callback:function ($$v) {_vm.$set(_vm.editedItem, "quantidadeProdutoPregao", _vm._n($$v))},expression:"editedItem.quantidadeProdutoPregao"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btn-cancelar",attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.salveListaProdutosPregao}},[_vm._v("Salvar")])],1)],1)],1)],1)]},proxy:true},{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.imageUrl,"alt":"Imagem","width":"70px","height":"auto"}}),_c('p',{staticClass:"c-table__nome"},[_vm._v(" "+_vm._s(item.name))])]}},{key:"item.idGenericaProduto",fn:function(ref){
var item = ref.item;
return [_vm._v(" N°"+_vm._s(item.idGenericaProduto == null ? ":" : item.idGenericaProduto)+" ")]}},{key:"item.tipoUnidade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipoUnidade != null ? Object.keys(item.tipoUnidade)[0] : "-")+" ")]}},{key:"item.valorDeCompra",fn:function(ref){
var item = ref.item;
return [_c('v-currency-field',{staticClass:"input-moeda",attrs:{"readonly":"","prefix":"R$","value":item.valorDeCompra}})]}},{key:"item.valorProdutoPregao",fn:function(ref){
var item = ref.item;
return [_c('v-currency-field',{staticClass:"input-moeda",attrs:{"readonly":"","prefix":"R$","value":item.valorProdutoPregao}})]}},{key:"item.quantidadeProdutoPregao",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"input-quantidade",attrs:{"readonly":"","type":"number","prefix":"Quant."},model:{value:(item.quantidadeProdutoPregao),callback:function ($$v) {_vm.$set(item, "quantidadeProdutoPregao", _vm._n($$v))},expression:"item.quantidadeProdutoPregao"}})]}},{key:"item.valorTotal",fn:function(ref){
var item = ref.item;
return [_c('v-currency-field',{staticClass:"input-total",attrs:{"readonly":"","type":"number","disabled":"","value":isNaN(item.valorTotal) ? '0,00' : item.valorTotal,"prefix":"R$ "}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center d-flex"},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")])],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }