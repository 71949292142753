<template>
  <v-data-table
    :items-per-page="totalItensPageToNotas"
    :sort-by.sync="porcodigo"
    :sort-desc.sync="sortDesc"
    :headers="headers"
    :items="listaNotas"
    item-key="id"
    class="pt-10 c-table-lista__notas"
    :loading="loading"
    loading-text="Procurando Notas"
    :search="search"
    >
    <template class="pb-10" width="100px" v-slot:top>
      <v-toolbar flat>
       <v-row class="justify-center align-center">

        <v-col class="flex-grow-0 mb-0 pb-0" cols="1" sm="3" md="1">
          <v-switch
          class="c-btn-modo__edicao__empenho"
          v-model="modoEdicaoNota"
          label="Modo Edição">
          </v-switch>
         </v-col>

          <v-col class="flex-grow-0 mb-0 pb-0" cols="3" sm="3" md="3">
          <v-text-field
            outlined
            class="ml-3"
            readonly
            label="Valor do Empenho"
            :value="itemEmpenho.valorTotalEmpenho | dinheiro">
          </v-text-field>
          </v-col>

        <div class="u-hide">{{calcValorEmitido('valorNf')}} | {{verificarPendencias}}</div>
        <v-col class="flex-grow-0 mb-0 pb-0" cols="3" sm="3" md="3">
        <v-text-field
          outlined
          class="ml-3"
          readonly
          label="Valor Emitido"
          :value="somaValorTotalNotasEmitidas | dinheiro">
        </v-text-field>
        </v-col>

        <v-col class="flex-grow-0 mb-0 pb-0" cols="3" sm="3" md="3">
          <div class="u-hide">{{valorAemitir = itemEmpenho.valorTotalEmpenho - somaValorTotalNotasEmitidas }}</div>
        <v-text-field
          outlined
          class="ml-3"
          readonly
          label="Valor a Emitir"
          :value="valorAemitir | dinheiro">
        </v-text-field>
        </v-col>

        <v-col class="mr-auto align-self-start mb-0 pb-0" cols="4" sm="4" md="4">
        <v-text-field
          hint="Busque Por: Número ou Data da Nota"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar Notas"
          single-line
        ></v-text-field>
        </v-col>
        </v-row>

        <v-dialog :open-delay="dialogGerarNotaTempo" persistent fullscreen hide-overlay transition="dialog-bottom-transition" v-model="dialogGerarNota">

        <template v-slot:activator="{ on, attrs }">
          <v-btn absolute right fab dark large class="mr-n6 mx-2" color="#363494" elevation="9" v-bind="attrs"
            v-on="on">
            <v-icon large>note_add</v-icon>
          </v-btn>
        </template>

          <v-card>

            <v-toolbar class="mb-5" color="#363494" dark>
              <v-toolbar-title> <span class="text-h5">{{ formTitle }}</span></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn class="mx-2" icon>
                  <v-icon id="js-modal-nota__close" dark large @click="closeGerarNota">cancel</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col class="mb-0 pb-0" cols="12" sm="12" md="12">
                    <gerarNota
                    :itemFiltrado="itemFiltrado"
                    :primeiroEmpenho="primeiroEmpenho"
                    :somaValorTotalNotasEmitidas="somaValorTotalNotasEmitidas"
                    :isNota="isNota"
                    :itemEmpenho="itemEmpenho"
                    :listaNotas="produtosDaUltimaNota"> </gerarNota>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogoVerDadosNF" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>

            <v-toolbar class="mb-5" color="#75756d" dark>
              <v-toolbar-title> <span class="text-h5">{{ titleDadosNota }}</span></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn class="mx-2" icon>
                  <v-icon id="js-modal-dados__nota__close" dark large @click="closeVerDadosNF">cancel</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
           
               <v-container>
                <v-row>
                  <v-col class="mb-0 pb-0" cols="12" sm="12" md="12">       
                   <dadosNF
                   :itemFiltradoNota="itemFiltradoNota"
                   :dadosProdutosNF="dadosProdutosNF"
                   :clouseTelaDadosNota="clouseTelaDadosNota">
                   </dadosNF>
                  </v-col>
                </v-row>
              </v-container>
            
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDeleteNota" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Tem certeza de que deseja excluir os dados desta nota?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDeleteNota">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirmEmpenho">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>

    <template #item.id="{item}">
    <v-text-field class="c-table-lista__notas__item c-table-lista__notas__id u-hide-linha__input" label="ID" readonly outlined type="text" :value='item.id'>
    </v-text-field>
    </template>

    <template #item.notaRecibo="{item}">
    <v-text-field class="c-table-lista__notas__item u-hide-linha__input u-width-fixa__520" label="Nota/Recibo"
     readonly outlined type="text" :value='item.notaRecibo'>
    </v-text-field>
    </template>

     <template #item.dataRecibo="{item}">
        <v-text-field
        readonly
        outlined
        class="c-table-lista__notas__item u-hide-linha__input u-largura-135"
        label="Data Nota/Recibo"
        :value='formatData(item.dataRecibo)'
        ></v-text-field>
    </template>

    <template #item.valorNf="{item}">
  
      <v-text-field
      class="c-table-lista__notas__item u-hide-linha__input u-width-fixa__220"
      label="Valor"
      outlined
      readonly
      :value='item.valorNf | dinheiro'>
      </v-text-field>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      
      <div class="text-center d-flex ">

        <v-btn v-if="modoEdicaoNota && item.id == listaNotas[listaNotas.length - 1].id" class="mx-2" fab dark x-small color="#b5b5b5">
        <v-icon dark @click="deleteNota(item)">delete</v-icon>
      </v-btn>

      <v-badge
        v-if="item.edicao"
        bordered
        color="error"
        icon="priority_high"
        overlap
      >
      <v-btn class="mx-2" fab dark x-small color="#b5b5b5">
        <v-icon color="#75756d" dark @click="verDadosNF(item)">upload_file</v-icon>
      </v-btn>
      </v-badge>
      <v-badge
        v-else
        bordered
        color="green"
        icon="done_outline"
        overlap
      >
      <v-btn class="mx-2" fab dark x-small color="#b5b5b5">
        <v-icon color="#75756d" dark @click="verDadosNF(item)">upload_file</v-icon>
      </v-btn>
      </v-badge>
      </div>
    </template>

  </v-data-table>
</template>

<script>
import axios from "axios";
import { urlApi, showError } from "@/global";
import barramento from "@/barramento"
import gerarNota from "../notas/gerarNota"
import dadosNF from "../notas/dadosNF"

export default {
  name: "listaNotas",
  props:{
    listaNotas: [],
    itemFiltrado: [],
    itemEmpenho: {},
    isNota: [Boolean, Function],
    primeiroEmpenho: [Boolean, Function],
  },
  components: { gerarNota, dadosNF },
  
  data: function() {
    return {
      porcodigo: 'id',
      sortDesc: true,
      search: "",
      dialogGerarNota: false,
      dialogGerarNotaTempo: 1500,
      dialogoVerDadosNF: false,
      dialogDeleteNota: false,

      headers: [
        { text: "ID", value: "id" },
        { text: "Nota/Recibo", value: "notaRecibo" },
        { text: "Data Recibo", value: "dataRecibo" },
        { text: "Valor NF", value: "valorNf" },
        { text: "Ações", value: "actions", sortable: false }
      ],

      valorAemitir: 0,
      loading: false,
      editedIndex: -1,
      listaNotasDados: this.listaNotas,
      somaValorTotalNotasEmitidas: 0,
      valTotalEmissaoNF: {},
      execNota: false,
      dadosProdutosNF: [],
      itemFiltradoNota:[],
      modoEdicaoNota: false,
      pendencias: 0,
      pendencia: {},
      produtosDaUltimaNota: [],
      ultimaNota: Number,
      idEmpenhoParaDadosNf: this.itemEmpenho.idEmpenho,
      clouseTelaDadosNota: Boolean,
      totalItensPageToNotas: -1
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Gerar Nota" : "Lista de Notas";
    },
    titleDadosNota() {
      return this.editedIndex === -1 ? 'Dados da Nota Fiscal': "Lista de Notas";
    },
    verificarPendencias() {
      this.pendencias = 0
        if(!!this.listaNotas.length){
       this.listaNotas.map(itemNota => itemNota.edicao).reduce((resultado, pendencia)=> {
          if(pendencia === true) {
            this.pendencias += 1}
            },this.listaNotas[0].edicao)
        }
        return this.pendencias == 0 ? false : true
      }
    },
  watch: {
    dialogGerarNota(val) {
      if(val){
        this.execNota = false
        if(this.listaNotas.length > 0) {
          this.produtosDaUltimaNota = this.listaNotas[this.listaNotas.length - 1].listaProdutosNf
          this.atualizarStateNotas(this.produtosDaUltimaNota)
          this.zerarDadosUltimaNf(this.produtosDaUltimaNota)
        }
      }
      if(val) this.zerarDadosPrimeiraNf()
      val || this.closeGerarNota()
    },

    dialogoVerDadosNF(val){
      val || this.closeVerDadosNF()
    },

    somaValorTotalNotasEmitidas(novo, antigo){
      if(novo != antigo) {
        if(!!this.listaNotas.length && novo > 0) {this.valTotalEmissaoNF.quantidadeNf = this.listaNotas.length} else { this.valTotalEmissaoNF.quantidadeNf = 0}
        this.atualizarValorEmitidoNf(novo)
        }
    },

    dialogDeleteNota(val) {
      val || this.closeDeleteNota()
    },

    verificarPendencias(val){
      val ? this.atualizarNotficacaoNf(true) : this.atualizarNotficacaoNf(false)
    },
  },

  methods: {
    getUltimaNota(){
      let idEmpenho = this.itemEmpenho.id
      let idUltimaNota = this.listaNotas[this.listaNotas.length - 1]
      axios
      .get(`${urlApi}/notas/doEmpenho?idEmpenho=${idEmpenho}&ultimaNota=${idUltimaNota.id}`, idUltimaNota)
      .then(res=> {
        this.produtosDaUltimaNota = res.data[0].listaProdutosNf
        this.atualizarStateNotas(this.produtosDaUltimaNota)
        this.zerarDadosUltimaNf(this.produtosDaUltimaNota)
        })
      .catch(showError)
    },
    zerarDadosPrimeiraNf(){
        for(let index in this.itemEmpenho.listaProdutosEmpenho){
          this.itemEmpenho.listaProdutosEmpenho[index].emitidaNaNota = 0
        }    
      },
      
    zerarDadosUltimaNf(item){
        for(let ind in item){
          item[ind].registroEmissaoNf = 0
        }    
      },
    atualizarStateNotas(item){
          if(!this.execNota){
            for (let posicao in item){
              if(item[posicao].quantNotaState == undefined){
                item[posicao].quantNotaState = item[posicao].quantParaNota
                item[posicao].quantParaEmitir = item[posicao].quantParaNota
              }
              item[posicao].state = item[posicao].quantNotaState
              
            item[posicao].emitidaNaNotaAtual = 0
            item[posicao].valorDoItemNaNota = 0
        }
          this.execNota =  true
        }      
      },

    calcValorEmitido(chave) {
       this.somaValorTotalNotasEmitidas =  this.listaNotas.reduce((a, b) => a + (b[chave] || 0), 0)
    },

    verDadosNF(item) {
      item.dataRecibo = this.formatData(item.dataRecibo)
      this.dadosProdutosNF =  item
      this.dialogoVerDadosNF = true
      this.itemFiltradoNota = item.listaProdutosNf.filter(produto => produto.registroEmissaoNf != 0)
    },

    closeGerarNota() {
      this.dialogGerarNota = false;
      barramento.EnviarNotas(true, this.itemEmpenho.id)
      
    },

    closeVerDadosNF() {
      this.dialogoVerDadosNF = false
      this.clouseTelaDadosNota = !true
    },

    formatData(data, index){
      if(data === null || data == undefined){
          return '00/00/0000'
      }
      else if (data.length > 10){

        let ano = ''
        let mes = ''
        let dia = ''

      data.split('-',2).filter((v, i , array) => ano = array[0]);
      data.split('-',2).filter((v, i , array) => mes = array[1]);
      data.match(/[\d{2}]+(?=T)/gi).filter((v, i , array) => dia = array[0]);
      
      return `${dia}/${mes}/${ano}`
      }
      else if(data.length == 10){
        return data
        }
    },
    formatDataNotaListaNotas (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },

    atualizarValorEmitidoNf(valorEmitido){
      this.valTotalEmissaoNF.valEmitidoNF = valorEmitido
        axios.patch(`${urlApi}/atualizarDadosNf/${this.itemEmpenho.id}`, this.valTotalEmissaoNF)
          .then(res =>{
          this.itemEmpenho.valEmitidoNF = valorEmitido
          this.itemEmpenho.quantidadeNf = this.valTotalEmissaoNF.quantidadeNf})
          .catch(showError)
      },

    deleteNota(item) {
      this.editedIndex = this.listaNotasDados.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDeleteNota = true
    },

    deleteItemConfirmEmpenho() {
      axios
        .delete(`${urlApi}/nota/${this.editedItem.id}`, this.editedItem)
        .then(res => {
          this.listaNotasDados.splice(this.editedIndex, 1)
          barramento.EnviarNotas(true, this.itemEmpenho.id)
          this.modoEdicaoNota = false
          this.$toasted.global.defaultSuccess()
          })
        .catch(showError)
      this.closeDeleteNota()
    },

    closeDeleteNota() {
        this.dialogDeleteNota = false;
        this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    deleteUltimNota(){
       this.ultimaNota = this.listaNotas[this.listaNotas.length - 1].id
    },

    atualizarNotficacaoNf(isTrue){
      this.pendencia.notificaoNf = isTrue
      axios.patch(`${urlApi}/atualizarDadosNf/${this.itemEmpenho.id}`, this.pendencia)
      .then(res => {
        this.itemEmpenho.notificaoNf = this.pendencia.notificaoNf
        })
      .catch(showError)
    }
  },
  
};
</script>

<style>
.u-hide-linha__input .v-input__slot:before, .u-hide-linha__input .v-text-field__details {
    content: "";
    display: none;
}
.c-table-lista__notas table{
    margin-top: 80px;
}
.c-table-lista__notas tr {
    height: 90px;
}
.c-table-lista__notas td {
    padding: 20px 10px!important;
}

.c-table-lista__notas tr {
    outline: solid 4px #ffffff!important;
    background: #f5f5f5;
}
.c-table-lista__notas .v-data-table__wrapper {
    border-radius: 7px;
}
.c-table-lista__notas {
    width: 1250px;
}
.c-table-lista__notas__id {
    width: 60px;
}
.c-table-lista__notas tr {
    height: 12px;
}

</style>