<template>
  <v-container
    :key="novoProduto"
    fluid
    class="pa-5"
  >
    <v-fade-transition appear>
      <v-row justify="center">
        <v-col
          cols="12"
          sm="6"
        >
          <v-sheet
            color="#943634"
            class="rounded-xl"
          >
            <h3 class="font-weight-medium white--text pl-5 pt-5">
              VALORES DE COMPRA
              <p
                v-if="!isGrafico"
                class="pa-3"
                >Não há dados suficientes para gerar gráficos!</p
              >
            </h3>
            <v-sparkline
              :key="novoProduto"
              :value="valoresDeVendaGrafico(grafValCompra)"
              :gradient="selectedGradient"
              height="100px"
              padding="24"
              stroke-linecap="round"
              smooth="1"
              auto-draw
              type="trend"
              class="c-graficos"
              show-labels
            >
              <template v-slot:label="item"
                ><tspan
                  dy="-5%"
                  dx="-1%"
                  >{{ evolucaVenda(grafValCompra)[item.index].valor }}</tspan
                >
                <tspan
                  dy="5%"
                  dx="-3%"
                  >{{ evolucaVenda(grafValCompra)[item.index].mesAno }}</tspan
                >
              </template>
            </v-sparkline>
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-sheet
            color="#943634"
            class="rounded-xl"
          >
            <h3 class="font-weight-medium white--text pl-5 pt-5">
              VALORES DE VENDA
              <p
                v-if="!isGrafico"
                class="pa-3"
                >Não há dados suficientes para gerar gráficos!</p
              >
            </h3>
            <v-sparkline
              :key="novoProduto"
              :value="valoresDeVendaGrafico(grafValVenda)"
              :gradient="selectedGradient"
              height="100px"
              padding="24"
              stroke-linecap="round"
              smooth="1"
              auto-draw
              type="trend"
              class="c-graficos"
              show-labels
            >
              <template v-slot:label="item"
                ><tspan
                  dy="-5%"
                  dx="-1%"
                  >{{ evolucaVenda(grafValVenda)[item.index].valor }}</tspan
                >
                <tspan
                  dy="5%"
                  dx="-3%"
                  >{{ evolucaVenda(grafValVenda)[item.index].mesAno }}</tspan
                >
              </template>
            </v-sparkline>
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          sm="12"
        >
          <v-sheet
            color="#943634"
            class="rounded-xl"
          >
            <h3 class="font-weight-medium white--text pl-5 pt-5">
              QUANTIDADE CADASTRADA
              <p v-if="!isGrafico"
                class="pa-3">Não há dados suficientes para gerar gráficos!</p
              >
            </h3>
            <v-sparkline
              :key="novoProduto"
              :value="valoresDeVendaGrafico(grafValQuant)"
              :gradient="selectedGradient"
              label-size="10"
              line-width="1.5"
              height="30px"
              padding="20"
              stroke-linecap="round"
              auto-draw
              smooth="1"
              type="trend"
              class="c-graficos"
              show-labels
            >
              <template v-slot:label="item"
                ><tspan
                  dy="-15%"
                  dx="-2%"
                  >{{ evolucaVenda(grafValQuant)[item.index].valor }}</tspan
                >
                <tspan
                  dy="15%"
                  dx="-2%"
                  >{{ evolucaVenda(grafValQuant)[item.index].mesAno }}</tspan
                >
              </template>
            </v-sparkline>
          </v-sheet>
        </v-col>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
  const gradients = [["#ececec", "#b8b8b8", "#ececec"]];
  export default {
    name: "graficosProdutos",
    props: {
      quantidadesCadastradas: Array,
      valoresDeCompra: Array,
      valoresDeVenda: Array,
      idProduto: Number,
    },
    components: {},
    data: (vm) => ({
      isGrafico: true,
      grafValQuant: [],
      isGrafValQuant: true,
      grafValCompra: [],
      isGrafValCompra: true,
      grafValVenda: [],
      isGrafValVenda: true,
      novoProduto: "-1",
      selectedGradient: gradients[0],
      value: [423, 446, 675, 510, 590, 610, 760],
    }),
    watch: {
      valoresDeVenda: {
        handler(novaQuery, antigaQuery) {
          if (novaQuery !== antigaQuery) {
            this.novoProduto = this.idProduto;
            this.grafValVenda = novaQuery;
          }
        },
        immediate: true,
      },
      valoresDeCompra: {
        handler(novaQuery, antigaQuery) {
          if (novaQuery !== antigaQuery) {
            this.grafValCompra = novaQuery;
            if (this.grafValCompra.length == 1) {
              this.isGrafico = false;
            }
          }
        },
        immediate: true,
      },
      quantidadesCadastradas: {
        handler(novaQuery, antigaQuery) {
          if (novaQuery !== antigaQuery) {
            this.grafValQuant = novaQuery;
          }
        },
        immediate: true,
      },
    },
    computed: {
      valoresDeVendaGrafico() {
        return (valoresVenda) => {
          if (valoresVenda === undefined) {
            this.isGrafValQuant = false;
            this.isGrafValCompra = false;
            this.isGrafValVenda = false;
            return [0];
          } else {
            return valoresVenda.map((e) => e.valor);
          }
        };
      },
      evolucaVenda() {
        return (mesVenda) => {
          if (mesVenda === undefined) {
            this.isGrafValVenda = false;
            return "";
          } else {
            return mesVenda.map((e) => {
              let mes = new Date(e.mes).toLocaleString("pt-BR", {
                month: "numeric",
              });
              let ano = new Date(e.mes)
                .toLocaleString("pt-BR", { year: "numeric" })
                .toString()
                .substr(-2);
              let vendaEmes = {
                valor: e.valor,
                mesAno: `${mes}/${ano}`,
              };
              return vendaEmes;
            });
          }
        };
      },
    },
    methods: {},
    created() {},
    beforeMount() {},
    mounted() {},
  };
</script>

<style>
  .c-graficos text {
    color: white;
    font-size: 4px;
    user-select: none;
  }
</style>