<template>
  <v-data-table
    :items-per-page="totalItensPageToPregao"
    :headers="headers"
    :items="dadosPregao"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    item-key="id"
    class="mt-5 c-table-lista-pregao"
    :loading="loading"
    loading-text="Procurando pregão"
    :search="search"
    calculate-widths
  >
    <template
      width="100px"
      v-slot:top
    >
      <v-toolbar flat>
        <v-dialog
          persistent
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          v-model="dialogListaEmpenhosItem"
        >
          <v-card>
            <v-toolbar
              class="mb-5"
              color="#943634"
              dark
            >
              <v-toolbar-title>
                <span class="text-h6"
                  >Empenhos do Pregão N°: {{ itemPregao.idPregao }} | Órgão:
                  {{ itemPregao.nomeOrgao }}
                </span></v-toolbar-title
              >
              <v-spacer />
              <v-toolbar-items>
                <v-btn
                  class="mx-2"
                  icon
                >
                  <v-icon
                    dark
                    large
                    @click="closeListaEmpenho"
                    >cancel</v-icon
                  >
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    class="d-flex justify-center mb-0 pb-0 qualclass"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                  >
                    <listaEmpenho
                      :modoEdicao="modoEdicao"
                      :isEmpenho="isEmpenho"
                      :listaEmpenhos="listaEmpenhos"
                      :itemPregao="itemPregao"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogEditInfoPregao"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Editar Dados Pregão</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.idPregao"
                      label="Número Pregão"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="7"
                    md="7"
                  >
                    <v-text-field
                      v-model="editedItem.pneNi"
                      label="Editar NI"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="7"
                    md="7"
                  >
                    <v-text-field
                      v-model="editedItem.pneHs"
                      label="Editar HS"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="7"
                    md="7"
                  >
                    Data Final Atual:
                    <v-chip
                      class="text-caption white--text"
                      color="#823634"
                    >
                      {{ editFormatData(editedItem.dataFinal) }}
                    </v-chip>
                    <v-text-field
                      v-model="editedItem.dataFinal"
                      class="form-control"
                      type="date"
                      label="Nova Data Final"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="7"
                    md="7"
                    class="c-arquivar-pregao ma-0 pa-0 px-8 rounded-pill"
                  >
                    <v-switch
                      v-model="editedItem.arquivado"
                      :label="
                        editedItem.arquivado
                          ? 'Pregão Arquivado'
                          : 'Pregão Ativo'
                      "
                      color="#733634"
                    >
                    </v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="btn-cancelar"
                color="blue darken-1"
                text
                @click="fecharEdicaoPregao"
                >Cancelar</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="salvarAlteracao"
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogDeletePregao"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="text-h5"
              >Tem certeza de que deseja excluir este pregão?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeDeletePregao"
                >Cancelar</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="deleteItemConfirmPregao"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template #item.id="{ item }">
      <v-text-field
        dense
        class="c-table-lista-pregao__item u-hide-linha__input"
        readonly
        type="text"
        :value="item.id"
      >
      </v-text-field>
    </template>

    <template #item.nomeOrgao="{ item }">
      <v-tooltip
        top
        color="#943634"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            v-bind="attrs"
            v-on="on"
            class="c-table-lista-pregao__item u-hide-linha__input mt-3 u-width-fixa__360"
            :class="classModEdit"
            label="N° Pregão"
            readonly
            outlined
            type="text"
            :value="item.idPregao"
          >
          </v-text-field>
        </template>
        {{ item.idPregao }}
      </v-tooltip>
          
      <v-text-field
        dense
        class="c-table-lista-pregao__item u-hide-linha__input mt-3 u-width-fixa__360"
        :class="classModEdit"
        label="Nome do Órgão"
        readonly
        outlined
        type="text"
        :value="item.nomeOrgao"
      >
      </v-text-field>
    </template>

    <template #item.dataInicial="{ item }">
      <v-text-field
        readonly
        outlined
        class="c-table-lista-pregao__item u-hide-linha__input u-width-fixa__115"
        label="Data Inicial"
        :value="formatData(item.dataInicial)"
      ></v-text-field>
    </template>

    <template
      class="texttm"
      #item.dataFinal="{ item }"
    >
      <span class="c-data-final">
        <v-text-field
          readonly
          outlined
          class="c-table-lista-pregao__item u-hide-linha__input u-width-fixa__115"
          label="Data Final"
          :value="formatData(item.dataFinal)"
        ></v-text-field>
        <span class="c-data-restante u-position__absoluto">
          {{ (item.diasRestante = calcDiasRestante(item)) }} DIAS RESTANTE
        </span>
      </span>
    </template>

    <template #item.pneNi="{ item }">
      <span class="c-item-pneNieHS">
        NI:{{ item.pneNi }}<br />HS:{{ item.pneHs }}
      </span>
    </template>

    <template #item.saldoPregao="{ item }">
      <span class="c-saldo-restante">
        <v-text-field
          class="c-table-lista-pregao__item u-hide-linha__input u-width-fixa__140"
          label="Valor Total"
          outlined
          readonly
          :value="item.saldoPregao | dinheiro"
        >
        </v-text-field>
        <div class="c-valor-entregue u-position__absoluto">
          <span class="c-valor-entregue__valor"
            >EMPENHADO: {{ item.valorEntregue | dinheiro }}</span
          >
        </div></span
      >
    </template>

    <template #item.valorRestante="{ item }">
      <div class="u-hide">
        {{ (item.valorRestante = item.saldoPregao - item.valorEntregue) }}
      </div>
      <v-text-field
        class="c-table-lista-pregao__item u-hide-linha__input u-width-fixa__140"
        label="Saldo Restante"
        outlined
        readonly
        :value="item.valorRestante | dinheiro"
      >
      </v-text-field>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-col
        class="text-center d-flex ma-0 pa-0"
        cols="1"
        sm="2"
        md="1"
      >
        <v-btn
          v-if="modoEdicao"
          class="mx-2"
          fab
          dark
          x-small
          color="#943634"
        >
          <v-icon
            dark
            @click="editarDadosPregao(item)"
            >edit</v-icon
          >
        </v-btn>

        <v-btn
          v-if="modoEdicao"
          class="mx-2"
          fab
          dark
          x-small
          color="#943634"
        >
          <v-icon
            dark
            @click="deletePregao(item)"
            >delete</v-icon
          >
        </v-btn>

        <v-badge
          v-if="
            !item.notificaoNf &
            (item.notificaoNf != null) &
            (item.valorEntregue != 0)
          "
          color="green"
          icon="done_outline"
          class="pa-0 ma-0"
          bordered
          overlap
        >
          <v-btn
            class="mx-2"
            fab
            dark
            x-small
            color="#943634"
          >
            <v-icon
              dark
              @click="visualizarDadosPregao(item)"
              >visibility</v-icon
            >
          </v-btn>
        </v-badge>

        <v-badge
          v-else
          class="pa-0 ma-0"
          bordered
          color="error"
          icon="priority_high"
          overlap
        >
          <v-btn
            class="mx-2"
            fab
            dark
            x-small
            color="#943634"
          >
            <v-icon
              dark
              @click="visualizarDadosPregao(item)"
              >visibility</v-icon
            >
          </v-btn>
        </v-badge>
      </v-col>
    </template>
  </v-data-table>
</template>

<script>
  import axios from "axios";
  import { urlApi, showError } from "@/global";
  import barramento from "@/barramento";
  import listaEmpenho from "../empenho/listaEmpenho";

  export default {
    name: "listaPregao",
    components: { listaEmpenho },
    props: {
      modoEdicao: Boolean,
      arquivado: Boolean,
    },
    data: function () {
      return {
        search: "",
        dialogEditInfoPregao: false,
        dialogListaEmpenhosItem: false,
        dialogDeletePregao: false,

        headers: [
          {
            text: "id",
            class: "u-hide",
            cellClass: "u-hide",
            align: "start",
            value: "id",
          },
          {
            text: "N° Pregão",
            class: "u-hide",
            cellClass: "u-hide",
            sortable: false,
            value: "idPregao",
          },
          {
            text: "N° Pregão / Nome do Orgão",
            class: "c-pregao-thead ma-0 py-0",
            cellClass: "ma-0 py-0",
            sortable: false,
            value: "nomeOrgao",
          },
          {
            text: "Data Inicial",
            class: "c-pregao-thead",
            value: "dataInicial",
          },
          { text: "Data Final", class: "c-pregao-thead", value: "dataFinal" },
          {
            text: "PNE",
            class: "c-pregao-thead",
            sortable: false,
            value: "pneNi",
          },
          {
            text: "Valor Total",
            class: "c-pregao-thead",
            value: "saldoPregao",
          },
          {
            text: "Saldo Restante",
            class: "c-pregao-thead",
            value: "valorRestante",
          },
          {
            text: "Ações",
            class: "c-pregao-thead",
            value: "actions",
            sortable: false,
          },
        ],

        sortDesc: true,
        sortBy: "id",
        classModEdit: String,

        valorEntreguePregao: 0,
        itemPregao: [],
        listaEmpenhos: [],
        diasRestante: Number,
        loading: true,
        dadosPregao: [],
        editedIndex: -1,
        editedItem: {},
        defaultItem: {},
        isEmpenho: Boolean,
        dadosAlteradosPregao: {},
        totalItensPageToPregao: 15,
      };
    },
    computed: {
      editFormatData() {
        return (dataFinal) => {
          if (!dataFinal) return null;
          const dataBr = new Date(dataFinal).toISOString().substr(0, 10);
          const [year, month, day] = dataBr.split("-");
          return `${day}/${month}/${year}`;
        };
      },
    },
    watch: {
      dialogListaEmpenhosItem(val) {
        val || this.closeListaEmpenho();
      },

      dialogEditInfoPregao(val) {
        val || this.fecharEdicaoPregao();
      },

      dialogDeletePregao(val) {
        val || this.closeDeletePregao();
      },

      modoEdicao(val) {
        val
          ? (this.classModEdit = "u-width-fixa__170")
          : (this.classModEdit = "u-width-fixa__360");
      },
      arquivado: {
        handler(novo, antigo) {
          if (novo) {
            this.getArquivados();
          } else {
            this.getDadosPregao();
          }
        },
        immediate: true,
      },
    },

    methods: {
      formatDataAlterada(dataFinal) {
        const dataBrPregao = new Date(dataFinal).toISOString().substr(0, 10);
        const [year, month, day] = dataBrPregao.split("-");
        return `${day}/${month}/${year}`;
      },
      toggleOrder() {
        this.sortDesc = true;
      },
      nextSort() {
        let index = this.headers.findIndex((h) => h.value === this.sortBy);
        index = (index + 1) % this.headers.length;
        this.sortBy = this.headers[index].value;
      },
      getListaEmpenhos(id) {
        axios
          .get(`${urlApi}/getEmpenhosPregao/${id}`)
          .then((res) => {
            res.data.filter((e) => {
              for (var i = 0; i < e.listaProdutosEmpenho.length; i++) {
                e.listaProdutosEmpenho[i].idUnico = i;
              }
            });
            this.listaEmpenhos = res.data;
            this.listaEmpenhos.length >= 1
              ? (this.isEmpenho = true)
              : (this.isEmpenho = false);
          })
          .catch(showError);
      },
      getDadosPregao() {
        this.loading = true;
        axios
          .get(`${urlApi}/pregao`)
          .then((res) => {
            this.dadosPregao = res.data;
          })
          .catch(showError);
        this.loading = false;
      },
      getArquivados() {
        this.loading = true;
        axios
          .get(`${urlApi}/pregaoArquivados`)
          .then((res) => {
            this.dadosPregao = res.data;
          })
          .catch(showError);
        this.loading = false;
      },
      sendAlterarDadosPregao(itemPregao) {
        this.dadosAlteradosPregao.isNotificacao = false;
        this.dadosAlteradosPregao.dataFinal = itemPregao.dataFinal;
        this.dadosAlteradosPregao.idPregao = itemPregao.idPregao;
        this.dadosAlteradosPregao.pneNi = itemPregao.pneNi;
        this.dadosAlteradosPregao.pneHs = itemPregao.pneHs;
        this.dadosAlteradosPregao.arquivado = itemPregao.arquivado;

        axios
          .patch(
            `${urlApi}/atualizarPregao/${itemPregao.id}`,
            this.dadosAlteradosPregao
          )
          .then((res) => {
            if (this.arquivado) {
              if (!itemPregao.arquivado) {
                this.getArquivados();
              }
            } else {
              if (itemPregao.arquivado) {
                this.getDadosPregao();
              }
            }
          })
          .catch(showError);
      },
      visualizarDadosPregao(item) {
        this.getListaEmpenhos(item.id);
        this.itemPregao = item;
        this.editedIndex = this.dadosPregao.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogListaEmpenhosItem = true;
      },

      editarDadosPregao(item) {
        this.editedIndex = this.dadosPregao.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogEditInfoPregao = true;
      },

      salvarAlteracao() {
        if (this.editedIndex > -1) {
          Object.assign(this.dadosPregao[this.editedIndex], this.editedItem);
        }
        this.sendAlterarDadosPregao(this.editedItem);

        this.fecharEdicaoPregao();
      },

      fecharEdicaoPregao() {
        this.dialogEditInfoPregao = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      formatData(data) {
        if (!data) return null;
        return this.formatDataAlterada(data);
      },
      calcDiasRestante(item) {
        let dataI = "";
        let dataF = "";
        let result = "";
        let tempo = "";
        dataI = new Date();
        dataF = new Date(item.dataFinal);
        result = dataF - dataI;
        tempo = result / 1000 / 60 / 60 / 24;
        return Math.sign(tempo.toFixed(0)) == 1 ? tempo.toFixed(0) : "0";
      },

      deletePregao(item) {
        this.editedIndex = this.dadosPregao.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDeletePregao = true;
      },

      deleteItemConfirmPregao() {
        axios
          .delete(`${urlApi}/pregao/${this.editedItem.id}`, this.editedItem)
          .then((res) => {
            this.dadosPregao.splice(this.editedIndex, 1);
            this.getDadosPregao();
            this.$toasted.global.defaultSuccess();
          })
          .catch(showError);
        this.closeDeletePregao();
      },

      closeDeletePregao() {
        this.dialogDeletePregao = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      closeListaEmpenho() {
        this.dialogListaEmpenhosItem = false;
      },
    },

    mounted() {
      this.getDadosPregao();
    },

    created() {
      barramento.getBusca((busca) => {
        this.search = busca;
      });

      barramento.getLoadPregao((cadastrado) => {
        if (cadastrado) {
          this.getDadosPregao();

          this.toggleOrder();
        }
      });
      barramento.getLoadEmpenho((istrue, id) => {
        if (istrue) this.getListaEmpenhos(id);
      });
    },
  };
</script>

<style>
  .u-hide-linha__input .v-input__slot:before,
  .u-hide-linha__input .v-text-field__details {
    content: "";
    display: none;
  }
  .codIdTabela {
    position: absolute;
    opacity: 0;
  }
  .c-table-lista-pregao .c-pregao-thead {
    vertical-align: text-top;
    padding-top: 15px !important;
  }

  .c-table-lista-pregao thead tr {
    height: 30px;
  }

  .c-table-lista-pregao tr {
    height: 90px;
  }
  .c-data-final {
    position: relative;
  }
  .c-saldo-restante {
    position: relative;
  }
  .u-position__absoluto {
    position: absolute;
    border-radius: 20px;
    font-weight: 600;
    margin-top: 5px;
    text-align: center;
  }
  .c-table-lista-pregao td {
    padding: 35px 10px !important;
  }
  .c-item-pneNieHS {
    background: #dfdfdf;
    height: 88%;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    display: flex;
    text-align: center;
    border-radius: 5px;
    padding: 7px;
  }
  .c-table-lista-pregao tr {
    outline: solid 4px #ffffff !important;
    background: #f5f5f5;
  }
  .c-table-lista-pregao .v-data-table__wrapper {
    border-radius: 7px;
  }
  /* .c-table-lista-pregao {
    width: 1250px;
} */
  .c-pregao-thead_id {
    width: 0px;
    padding-right: 10px !important;
  }
  .c-pregao-thead_id .mdi {
    top: -19px;
    right: -12px;
  }
  .c-data-restante {
    width: 170px;
    left: -27px;
    padding: 1px;
    font-size: 12px;
    color: #1976d2;
  }
  .c-valor-entregue {
    width: 170px;
    left: 2px;
    padding: 1px;
    text-align: left;
    font-size: 12px;
    color: #009124;
}
  .u-width-fixa__100 {
    width: 100px !important;
  }
  .u-width-fixa__140 {
    width: 140px;
  }
  .u-width-fixa__170 {
    width: 170px!important;
  }
  .u-width-fixa__360 {
    width: 360px;
  }
  #list-55.v-select-list div:nth-child(4) {
    display: none;
  }
  .c-arquivar-pregao {
    background: #e6e6e6;
  }
</style>
