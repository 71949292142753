<template>
  <v-card
    flat
    v-scroll="onScroll"
    class="overflow-hidden"
  >
    <v-app-bar
      :absolute="ab"
      :fixed="fx"
      :elevation="boxShedon"
      height="extended"
      class="pt-7"
    >
      <v-row class="c-app-bar__pregao d-flex justify-end pa-2 pr-6">
        <v-switch
          class="c-btn-modo__edicao"
          v-model="modoEdicao"
        >
          <template v-slot:label>
            <v-chip
              class="pr-5"
              link
              >Modo Edição
              <v-icon
                right
                small
                >drive_file_rename_outline</v-icon
              ></v-chip
            >
          </template>
        </v-switch>
        <v-switch
          class="c-btn-modo__arquivado"
          v-model="arquivado"
        >
          <template v-slot:label>
            <v-chip
              class="pr-5"
              link
            >
              Pregões Arquivados
              <v-icon
                small
                v-if="!arquivado"
                right
                >visibility_off</v-icon
              >
              <v-icon
                small
                v-if="arquivado"
                right
                >visibility</v-icon
              >
            </v-chip>
          </template>
        </v-switch>

        <v-col
          class="mb-0 pb-0"
          cols="12"
          sm="12"
          md="6"
        >
          <v-text-field
            dense
            hint="Busque Por: Órgão, ID ou Valor"
            persistent-hint
            outlined
            @keyup="enviarBuscar"
            class="buscadorr"
            append-icon="mdi-magnify"
            label="Buscar Pregão"
          ></v-text-field>
        </v-col>
        <modalCadastroProduto />
        <modalCadastroOrgao />
        <modalCadastroPregao />
      </v-row>
    </v-app-bar>
    <v-sheet
      id="scrolling-techniques-7"
      class="d-flex justify-center overflow-y-auto pa-0 ma-0 overflow-y-auto"
      width="100%"
      max-height="100%"
    >
      <v-sheet
        width="98%"
        class="pt-7 pa-0 ma-0 listacascao"
        max-height="1000px"
        min-height="300px"
      >
        <listaPregao
          :modoEdicao="modoEdicao"
          :arquivado="arquivado"
        />
      </v-sheet>
    </v-sheet>
  </v-card>
</template>
<script>
  import listaPregao from "../pregao/listaPregao";
  import modalCadastroOrgao from "../orgao/modalCadastroOrgao";
  import modalCadastroPregao from "../pregao/modalCadastroPregao";
  import modalCadastroProduto from "../produto/modalCadastroProduto";
  import barramento from "@/barramento";

  export default {
    name: "Menu",
    components: {
      listaPregao,
      modalCadastroPregao,
      modalCadastroProduto,
      modalCadastroOrgao,
    },

    data: function () {
      return {
        searchEngine: "",
        modoEdicao: false,
        arquivado: false,
        ab: true,
        fx: false,
        boxShedon: "0",
      };
    },

    methods: {
      enviarBuscar(busca) {
        barramento.enviarBusca(busca.target.value);
      },
      onScroll(e) {
        if (e.path[1].scrollY >= 400) {
          (this.ab = false), (this.fx = true);
          this.boxShedon = "10";
        } else {
          (this.ab = true), (this.fx = false);
          this.boxShedon = "0";
        }
      },
    },
  };
</script>

<style>
  .c-btn-modo__edicao {
    position: absolute;
    left: 25px;
    top: -5px;
  }
  .c-btn-modo__arquivado {
    position: absolute;
    left: 25px;
    top: 35px;
  }
</style>