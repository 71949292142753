<template>
  <v-data-table
    :items-per-page="totalPageEmpenho"
    :headers="headers"
    :items="listaProdutosEmpenho = listaEmpenhos"
    item-key="idUnico"
    class="mt-2 c-table-empenho"
    :loading="loading"
    :search="search"
    hide-default-header
  >
    <template v-slot:top>
      <v-toolbar flat prominent class="mb-5">
        <v-row class="justify-center align-center">
        
        <v-col class="mb-0 pb-0" cols="3" sm="12" md="3">
          <!-- Calcular valor do saldo do pregao -->
         <div class="u-hide"> {{calcSaldoPregao('valorTotal')}} </div>
          <!--  Calcular valor total empenho -->
         <div class="u-hide"> {{calcValorEmpenho('valorItemEmpenhoAtual')}} </div>
        <v-text-field
          outlined
          readonly
          label="Saldo do Pregão"
          prefix="Saldo:"
          :value="valorTotalGeral | dinheiro">
        </v-text-field>
        </v-col>

        <v-col class="mb-0 pb-0 " cols="3" sm="12" md="3">
        <v-text-field
          class="ml-3"
          outlined
          readonly
          label="Valor Entregue"
          prefix="Entregue:"
          :value="somaValorTotalEntregueEmpenho | dinheiro">
        </v-text-field>
        </v-col>
        
         <v-col class="mb-0 pb-0 " cols="3" sm="12" md="3">
           <!-- Calcular valor restante do pregão -->
           <div class="u-hide">{{calcValorTotalRestante = valorTotalGeral - somaValorTotalEntregueEmpenho}}</div>
        <v-text-field
          class="ml-3"
          outlined
          readonly
          label="Valor Restante"
          prefix="Saldo:"
          :value="calcValorTotalRestante | dinheiro ">
        </v-text-field>
        </v-col>
        
        <v-col class="mb-0 pb-0 " cols="3" sm="12" md="3">
        <v-text-field
          class="ml-3"
          outlined
          readonly
          label="Valor do Empenho"
          prefix="Atual:"
          :value="valorTotalEmpenho | dinheiro">
        </v-text-field>
        </v-col>
        
        <v-col class="mb-0 pb-0 " cols="12" sm="12" md="5">
        <v-text-field
          v-model="search"
          hint="Busque Por: Nome do Produto"
          append-icon="mdi-magnify"
          label="Buscar produto"
          single-line
        ></v-text-field>
        </v-col>

        </v-row>
        <v-dialog v-model="dialogEditarValoresEmpenho" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  
                  <v-col class="mb-0 pb-0 " cols="12" sm="12" md="6">
                    <v-text-field disabled :value="editedItem.name" outlined></v-text-field>
                  </v-col>
                  
                  <v-col class="mb-0 pb-0 " cols="12" sm="12" md="3">
                    <v-text-field label="Quantidade Cadastrada" disabled type="number" v-model.number="editedItem.quantidadeProdutoPregao" outlined></v-text-field>
                  </v-col>

                  <v-col class="mb-0 pb-0 " cols="12" sm="12" md="3">
                    <v-text-field label="Quantidade Restante" disabled type="number" v-model.number="editedItem.proxEmpenho" outlined></v-text-field>
                  </v-col><v-spacer md="5"></v-spacer>

                  <v-col cols="12" sm="12" md="4">
                    <v-text-field type="number" disabled outlined v-model="editedItem.idGenericaProduto" label="ID do Item"></v-text-field>
                  </v-col>
                  
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field :rules="rulesQuantidade" class="u-clear-data" type="number" outlined v-model.number="editedItem.quantEmpenhoAtual" label="Adicionar Quantidade"></v-text-field>
                  </v-col>
                  
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="btn-cancelar" color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn v-if="editedItem.quantEmpenhoAtual > editedItem.proxEmpenho " color="blue darken-1" text @click="salveListaProdutosEmpenhoPrincipal" disabled>Salvar</v-btn>
              <v-btn v-else color="blue darken-1" text @click="salveListaProdutosEmpenhoPrincipal">Salvar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

       <!-- Dados da tabela -->

    <template #item.imageUrl="{item}">
      <img position="right right" class="mt-3 mb-0 rounded-lg elevation-1" :src="item.imageUrl" alt="Imagem" width="70px" height="auto" /><br />
      <p class="c-table__nome ma-0 pa-0">{{item.name}}</p>
      <p class="c-table__nome">Item: {{item.idGenericaProduto}} | {{ item.tipoUnidade != null ? Object.values(item.tipoUnidade)[0] : "-"}}</p>
    </template>

    <template #item.valorDeCompra="{ item }">
      <v-currency-field
        readonly
        class="c-table-empenho__item"
        prefix="R$"
        :value="item.valorDeCompra"
        hint="VALOR DE COMPRA"
        persistent-hint
        disabled
      />
    </template>

    <template #item.valorProdutoPregao="{item}">
      <v-text-field
      class="c-table-empenho__item"
      hint="VALOR DE VENDA NO PREGAO"
      persistent-hint
      disabled
      :value="item.valorProdutoPregao | dinheiroDecimal">
      </v-text-field>
    </template>

    <template #item.quantidadeProdutoPregao="{item}">
      <v-text-field
      class="c-table-empenho__item"
      disabled
      type="number"
      v-model.number="item.quantidadeProdutoPregao"
      hint="QUANTIDADE CADASTRADA"
      persistent-hint></v-text-field>
    </template>
     <template #item.valorTotal="{item}">
      <v-text-field
      class="c-table-empenho__item"
      disabled
      :value="item.valorTotal | dinheiro"
      hint="VALOR TOTAL CADASTRADO"
      persistent-hint>
      </v-text-field>
    </template>

    <template #item.quantRestante="{item}">  
      <v-text-field
      class="c-table-empenho__item"
      disabled
      type="number"
      v-model.number="item.quantRestante"
      hint="QUANTIDADE RESTANTE"
      persistent-hint></v-text-field>
    </template>

     <template #item.quantEmpenhoAtual="{item}">
      <v-text-field
      readonly
      class="u-clear-data c-table-empenho__item"
      type="number"
      v-model.number="item.quantEmpenhoAtual"
      hint="QUANTIDADE DO EMPENHO"
      persistent-hint></v-text-field>
    </template>

    <template #item.valorItemEmpenhoAtual="{item}">
      <div class="u-hide">{{item.valorItemEmpenhoAtual = item.quantEmpenhoAtual * item.valorProdutoPregao}}</div>
      <v-text-field
      class="u-clear-data c-table-empenho__item"
      hint="VALOR DO EMPENHO"
      persistent-hint
      disabled
      :value="item.valorItemEmpenhoAtual | dinheiro"> </v-text-field>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="text-center d-flex ">
      <v-btn class="mx-2" fab dark x-small color="#943634">
        <v-icon dark @click="editValEmpenhoPrincipal(item)">mdi-pencil</v-icon>
      </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";
import { urlApi, showError } from "@/global";
import barramento from "@/barramento"

export default {
  name: "ListaProdutosEmpenho",
  props:{
    listaEmpenhos: [],
    somaValorTotalEntregueEmpenho: [String, Number],
  },
  data: function() {
    return {
      loading: true,
      search: "",
      dialogEditarValoresEmpenho: false,

      headers: [
        { text: "Imagem", align: "start", sortable: false, value: "imageUrl" },
        { class: "u-hide", cellClass: "u-hide", text: "Produto", value: "name" },
        { class: "u-hide", cellClass: "u-hide", text: "N°Item", value: "idGenericaProduto" },
        { class: "u-hide", cellClass: "u-hide", text: "Un. de Medida", align: "center", value: "tipoUnidade" },
        { text: "Valor de Compra", value: "valorDeCompra" },
        { text: "Valor do Item Cadastrado", value: "valorProdutoPregao" },
        { text: "Quantidade Cadastrada", value: "quantidadeProdutoPregao"},
        { text: "Valor Total Cadastrado", value: "valorTotal"},
        { text: "Quantidade Restante", value: "quantRestante"},
        { text: "Quantidade do Empenho", value: "quantEmpenhoAtual"},
        { text: "Quantidade Restante", value: "valorItemEmpenhoAtual"},
        { text: "Ação", value: "actions", sortable: false }
      ],

      valorTotalGeral: 0,
      valorGeralEmpenhos: 0,
      valorTotalGeralEntregue: 0,
      listaProdutosEmpenho: [],
      sendProdutosEmpenho: [],
      editedIndex: -1,
      delProduto: {},
      editedItem: {},
      defaultItem: {},

      rulesQuantidade:[
        value => value <= this.editedItem.proxEmpenho || 'Quantidade superior ao restante'
      ],
      totalPageEmpenho: -1,
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Adicionar Produto" : "Add Quantidade deste Empenho";
    },
  },

  watch: {
    dialogEditarValoresEmpenho(val) {
      val || this.close();
    },
    istrueLoodEmpenho(val){
      if(!val) this.exec = false
    },
  },

  methods: {
    
    calcSaldoPregao(chave) {
       this.valorTotalGeral =  this.listaProdutosEmpenho.reduce((a, b) => a + (b[chave] || 0), 0)
    },

    calcValorEmpenho(chave) {
       this.valorTotalEmpenho =  this.listaProdutosEmpenho.reduce((a, b) => a + (b[chave] || 0), 0)
       barramento.EnviarTotalEmpenho(this.valorTotalEmpenho)
    },
    
    remover(produto) {
        axios
        .delete(`${urlApi}/empenhos/id`, produto)
        .then(() => this.$toasted.global.defaultSuccess())
        .catch(showError)
        },

    editValEmpenhoPrincipal(item) { 
        this.editedIndex = this.listaProdutosEmpenho.indexOf(item);
        item.proxEmpenho = item.state
        this.editedItem = Object.assign({}, item);
        this.dialogEditarValoresEmpenho = true;
        },

    close() {
        this.dialogEditarValoresEmpenho = false;
        this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
            });
        },

    salveListaProdutosEmpenhoPrincipal() {

        if (this.editedIndex > -1) {
              
              Object.assign(this.listaProdutosEmpenho[this.editedIndex], this.editedItem)

              this.listaProdutosEmpenho[this.editedIndex].valorItemEmpenhoGeral += this.listaProdutosEmpenho[this.editedIndex].valorItemEmpenhoAtual

              
              this.listaProdutosEmpenho[this.editedIndex].quantRestante = this.listaProdutosEmpenho[this.editedIndex].proxEmpenho - this.listaProdutosEmpenho[this.editedIndex].quantEmpenhoAtual;
              
              this.listaProdutosEmpenho[this.editedIndex].quantParaNota = this.listaProdutosEmpenho[this.editedIndex].quantEmpenhoAtual;

              this.listaProdutosEmpenho[this.editedIndex].proxEmpenho = this.listaProdutosEmpenho[this.editedIndex].quantRestante;
              this.listaProdutosEmpenho[this.editedIndex].emitidaNaNota = 0;

              barramento.EnviarProdutosEmpenho(this.listaProdutosEmpenho, false)

              } 
       
        this.close();
    },

  },

  mounted(){
   if(this.listaEmpenhos) this.loading = false;
 },

};
</script>
    
<style>
.textarea-nome{
  width: 90px;
}
.textarea-nome .v-input__slot:before {
    content: "";
    display: none;
}
.textarea-nome .v-text-field__details{
    display: none;
}

.textarea-nome textarea {
    line-height: 14px!important;
}
.c-table-empenho__item {
    width: 90px;
}
.c-table-empenho .v-messages__message {
    font-size: x-small;
    font-weight: 700;
   
}
</style>