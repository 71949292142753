<template>
  <v-data-table
    :headers="headers"
    :items="infoProduto"
    item-key="id"
    sort-by="name"
    class="mt-5"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar produto"
          single-line
          hide-details
        ></v-text-field>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <!-- Dialog Metricas Produto -->
        <v-dialog
          v-model="dialogMetricas"
          fullscreen
        >
          <v-card class="ma-0 pa-0">
            <v-card-text class="ma-0 pa-0">
              <metricasProdutos
                :todosProdutos="consultarMetricas"
                :dataProduto="dataProduto"
                @fecharMetricas="fecharMetrica($event)"
              />
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialog"
          max-width="800px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              color="primary"
              elevation="9"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.imageUrl"
                      label="Editar URL"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="7"
                    md="7"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Editar Nome"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="btn-cancelar"
                color="blue darken-1"
                text
                @click="close"
                >Cancelar</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="send"
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogDelete"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="text-h5"
              >Tem certeza de que deseja excluir este item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeDelete"
                >Cancelar</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template #item.imageUrl="{ item }">
      <img
        :src="item.imageUrl"
        alt="Imagem"
        width="70px"
        height="auto"
      />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="text-center d-flex">
        <!-- BTN editar produto -->
        <v-btn
          class="mx-2"
          fab
          dark
          x-small
          color="primary"
        >
          <v-icon
            dark
            @click="editItem(item)"
            >mdi-pencil</v-icon
          >
        </v-btn>
        <!-- BTN Excluir produto -->
        <v-btn
          class="mx-2"
          fab
          dark
          x-small
          color="primary"
        >
          <v-icon
            dark
            @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
        </v-btn>
        <!-- BTN Métricas produto -->
        <v-btn
          class="mx-2"
          fab
          dark
          x-small
          color="primary"
        >
          <v-icon
            dark
            @click="openDialogMetricas(item)"
            >insights</v-icon
          >
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
  import axios from "axios";
  import { urlApi, showError } from "@/global";
  import metricasProdutos from "./metricasProdutos.vue";
  export default {
    name: "cadastroProduto",
    components: { metricasProdutos },
    data: function () {
      return {
        dialogMetricas: false,
        consultarMetricas: [],
        dataProduto: {},
        search: "",
        dialog: false,
        dialogDelete: false,
        headers: [
          {
            text: "ID",
            align: "start",
            sortable: false,
            value: "id",
          },
          {
            text: "Imagem",
            align: "start",
            sortable: false,
            value: "imageUrl",
          },
          { text: "Produto", value: "name" },
          { text: "Ação", value: "actions", sortable: false },
        ],

        infoProduto: [],
        editedIndex: -1,
        delProduto: {},
        editedItem: {
          imageUrl: "",
          name: "",
        },
        defaultItem: {
          imageUrl: "",
          name: "",
        },
        produto: {},
        novoProdutoInserido: false,
      };
    },

    computed: {
      formTitle() {
        return this.editedIndex === -1 ? "Novo Produto" : "Editar Produto";
      },
    },

    watch: {
      dialog(val) {
        val || this.close();
      },
      dialogDelete(val) {
        val || this.closeDelete();
      },
    },

    methods: {
      fecharMetrica() {
        this.dialogMetricas = false;
      },
      openDialogMetricas(item) {
        this.dataProduto = item;
        this.dialogMetricas = true;
      },
      salve(produto) {
        axios
          .post(`${urlApi}/produto`, produto)
          .then(() => {
            this.$toasted.global.defaultSuccess();
            this.getProduto();
          })
          .catch(showError);
      },
      getProduto() {
        axios
          .get(`${urlApi}/produto`)
          .then((res) => {
            this.infoProduto = res.data;
            this.consultarMetricas = res.data;
          })
          .catch(showError);
      },

      initialize() {
        this.infoProduto = [];
      },

      editItem(item) {
        this.editedIndex = this.infoProduto.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      deleteItem(item) {
        this.editedIndex = this.infoProduto.indexOf(item);

        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
        this.delProduto = { ...this.editedItem };
      },

      deleteItemConfirm() {
        this.infoProduto.splice(this.editedIndex, 1);
        axios
          .delete(`${urlApi}/produto/${this.delProduto.id}`, this.delProduto)
          .then(() => this.$toasted.global.defaultSuccess())
          .catch(showError);
        this.closeDelete();
      },

      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },

      send() {
        if (this.editedIndex > -1) {
          Object.assign(this.infoProduto[this.editedIndex], this.editedItem);
        } else {
          this.infoProduto.push(this.editedItem);
        }
        this.salve(this.editedItem);
        this.close();
      },
    },
    mounted() {
      this.getProduto();
      this.initialize();
    },
  };
</script>

<style></style>