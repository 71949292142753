<template>
  <v-card class="d-flex flex-column align-center">
      <v-card-title>
          <v-row class="mt-3 justify-center align-center">

               <v-col class="d-flex flex-row align-center justify-end mb-0 pb-0" cols="12" sm="3" md="12">
                  <v-switch v-model="dadosProdutosNF.edicao" label="Existe Pendências?">
                  </v-switch>
                  <v-btn elevation="9" class="mx-2" fab dark small color="#363494"><v-icon dark @click="atualizarObservacaoNota">save</v-icon></v-btn>
              </v-col>

                <v-col class="flex-grow-0 mb-0 pb-0" cols="3" sm="3" md="3">
                  <v-text-field outlined class="ml-3" filled readonly label="VALOR DA NOTA"
                      :value="dadosProdutosNF.valorNf | dinheiro">
                  </v-text-field>
                </v-col>

              <v-col class="flex-grow-0 mb-0 pb-0" cols="12" lg="8" md="8" sm="6">
                  <v-text-field outlined class="ml-3" label="NOTA/RECIBO"
                      v-model="dadosProdutosNF.notaRecibo">
                  </v-text-field>
              </v-col>

              <v-col class="flex-grow-0 mb-0 pb-0" cols="2" lg="2" sm="2" md="2">
                  <v-text-field outlined readonly class="ml-3"
                  label="DATA NOTA/RECIBO"
                  v-model="dadosProdutosNF.dataRecibo">
                  </v-text-field>
              </v-col>

              <v-col class="flex-grow-0 mb-0 pb-0" cols="3" lg="3" sm="3" md="3">

              <v-menu
          ref="menu"
          v-model="menuDataRecibo"
          :close-on-content-click="false"
          transition="scale-transition"
          origin="center center"
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="dateReciboPiker.dataNova"
              label="EDITAR DATA RECIBO"
              hint="EDITE DATA DO RECIBO"
              persistent-hint
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            
            :active-picker.sync="activePickerDataRecibo"
            min="1950-01-01"
            @change="dateSave"
          ></v-date-picker>
        </v-menu>

        </v-col>

            <v-col cols="12" sm="12" md="12">
           <VueEditor placeholder="Edite suas observações da nota" v-model="dadosProdutosNF.observacoes" />
           </v-col>

          </v-row>
      </v-card-title>

    <v-card-title>
      <v-text-field
        class="ma-0 pa-0"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar Dados"
        single-line
        hide-details
      ></v-text-field>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="itemFiltradoNota"
      :search="search"
      class="mt-0 pt-0 c-table-lista__dados__notas"
    >
    <template #item.imageUrl="{item}">
      <img :src="item.imageUrl" alt="Imagem" width="70px" height="auto" />
    </template>
    
    <template #item.valorProdutoPregao="{item}">
      <v-text-field
      class="c-table-lista__empenho__item u-hide-linha__input u-desabilitado"
      filled
      readonly
      :value='item.valorProdutoPregao | dinheiro'>
      </v-text-field>
    </template>

    <template #item.registroEmissaoNf="{item}">
      <v-text-field
      class="c-table-lista__empenho__item u-hide-linha__input u-desabilitado"
      filled
      readonly
      type="number"
      v-model.number="item.registroEmissaoNf">
      </v-text-field>
    </template>

    <template #item.valorItemNotaEmitida="{item}">
      <div class="u-hide">{{ item.valorItemNotaEmitida = item.registroEmissaoNf * item.valorProdutoPregao}}</div>
      <v-text-field
      class="c-table-lista__empenho__item u-hide-linha__input u-desabilitado"
      filled
      readonly
      :value='item.valorItemNotaEmitida | dinheiro'>
      </v-text-field>
    </template>
    
    </v-data-table>

  </v-card>
</template>

<script>
import axios from "axios";
import { urlApi, showError } from "@/global";
import barramento from "@/barramento"
import { VueEditor } from "vue2-editor"

export default {
  name: "dadosNF",
  props:{
      dadosProdutosNF: [],
      itemFiltradoNota: [],
      listaDataReciboNota: String,
      clouseTelaDadosNota: [Function, Boolean]
  },
  components: {VueEditor },
  
data: function() {
    return {
      search: "",
      headers: [
        { text: "Img", value: "imageUrl" },
        { text: "Produto", value: "name" },
        { text: "ID", value: "idGenericaProduto" },
        { text: "Valor", value: "valorProdutoPregao" },
        { text: "Quantidade", value: "registroEmissaoNf"},
        { text: "Total", value: "valorItemNotaEmitida"}
      ],
      dadosProdutosNotas: this.itemFiltradoNota,
      dadosObs: {},
      dateReciboPiker: {},
      menuDataRecibo: false,
      activePickerDataRecibo: null,
      }
},

watch: {
    menu(val) {
      val && setTimeout(() => (this.activePickerDataRecibo = "Ano"));
    },
    clouseTelaDadosNota(val){
      this.dateReciboPiker.dataNova = ''
    }
  },
 methods: {
   formatDateDadosNF (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },

   dateSave(date) {
      this.$refs.menu.save(date)
      this.dadosProdutosNF.dataRecibo = this.formatDateDadosNF(date)
      this.dateReciboPiker.dataNova = this.formatDateDadosNF(date)
    },

     formatData2(data, index){
      if(data === null){
          return
      }
      else if (data.length > 10){

        let ano = ''
        let mes = ''
        let dia = ''

      data.split('-',2).filter((v, i , array) => ano = array[0]);
      data.split('-',2).filter((v, i , array) => mes = array[1]);
      data.match(/[\d{2}]+(?=T)/gi).filter((v, i , array) => dia = array[0]);
      
      return `${dia}/${mes}/${ano}`
      }
      else if(data.length == 10){
        return data
        }
    },

      atualizarObservacaoNota(){
        
        this.dadosObs.observacoes = this.dadosProdutosNF.observacoes
        this.dadosObs.edicao = this.dadosProdutosNF.edicao
        this.dadosObs.notaRecibo = this.dadosProdutosNF.notaRecibo
        this.dadosObs.dataRecibo = this.dadosProdutosNF.dataRecibo

        axios.put(`${urlApi}/atualizarNota/${this.dadosProdutosNF.id}`, this.dadosObs )
        .then(() => {
          this.dateReciboPiker.dataNova = ''
          this.$toasted.global.defaultSuccess()
          })
        .catch(showError)
      },

      formatDataNota (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
    },
};
</script>

<style>
.u-hide-linha__input .v-input__slot:before, .u-hide-linha__input .v-text-field__details {
    content: "";
    display: none;
}
.c-table-lista__dados__notas table{
    margin-top: 80px;
}
.c-table-lista__dados__notas tr {
    height: 90px;
}
.c-table-lista__dados__notas td {
    padding: 20px 10px!important;
}

.c-table-lista__dados__notas tr {
    outline: solid 4px #ffffff!important;
    background: #f5f5f5;
}
.c-table-lista__dados__notas .v-data-table__wrapper {
    border-radius: 7px;
}
.c-table-lista__dados__notas {
    width: 1250px;
}
.c-table-lista__dados__notas__id {
    width: 60px;
}
.c-table-lista__dados__notas tr {
    height: 12px;
}
.c-table-lista__dados__notas table {
    margin-top: 5px;
}
.u-desabilitado {
    pointer-events: none;
}

</style>