<template>
  <v-form>
    <v-row class="custoB d-flex space-between ma-5">
      
      <v-col cols="12" sm="12" md="4">
        <v-autocomplete
        v-model="dadosPregao.nomeOrgao"
        :items="orgaosCadastrados"
        auto-select-first
        dense
        chips
        clearable
        deletable-chips
        label="Nome do Órgão">
        <template class="m-p-4" v-slot:label>
          <strong>Nome do Órgão</strong>
          <v-icon style="vertical-align: middle">apartment</v-icon>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <v-text-field
          outlined
          cols="12"
          sm="12"
          md="4"
          hint="Código do novo pregão"
          v-model="dadosPregao.idPregao"
        >
          <template class="m-p-4" v-slot:label>
            <strong>ID do Pregão</strong>
            <v-icon style="vertical-align: middle">mdi-key-variant</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-btn block x-large color="primary" @click="criarPregao" v-on:dblclick.prevent>
          <v-icon left>mdi-clipboard-check-outline</v-icon>SALVAR PREGÃO
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          origin="center center"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="dadosPregao.dataInicial"
              label="DATA INICIAL"
              hint="Data inicial do pregão"
              persistent-hint
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dadosPregao.dataInicial"
            :active-picker.sync="activePicker"
            min="1950-01-01"
            @change="dateSave"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          origin="center center"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="dadosPregao.dataFinal"
              label="DATA FINAL"
              hint="Data final do pregão"
              persistent-hint
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dadosPregao.dataFinal"
            :active-picker.sync="activePicker2"
            min="1950-01-01"
            @change="dateSave2"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-text-field v-model="dadosPregao.pneNi" outlined cols="12" sm="12" md="4">
          <template class="m-p-4" v-slot:label>
            <strong>PNE NI</strong>
            <v-icon style="vertical-align: middle">mdi-folder-key-network-outline</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-text-field v-model="dadosPregao.pneHs" outlined cols="12" sm="12" md="4">
          <template class="m-p-4" v-slot:label>
            <strong>PNE HS</strong>
            <v-icon style="vertical-align: middle">mdi-folder-key-network-outline</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <produtosPregao :produtosCadastrados="produtosCadastrados" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import produtosPregao from "./produtosPregao";
import axios from "axios";
import { urlApi, showError } from "@/global";
import barramento from "@/barramento";
export default {
  name: "cadastroPregao",
  components: { produtosPregao },
  props:{
    orgaosCadastrados: Array,
    produtosCadastrados: Array
  },

  data: () => ({
    dadosPregao: {},
    dadosProdutos: {},
    listaProdutos: [],
    activePicker: null,
    activePicker2: null,
    date: null,
    date2: null,
    menu: false,
    menu2: false,
    valorTotalPregao: {},
    idsProdutos: '',
    itemOrgao: []
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "Ano"));
    },
    menu2(val) {
      val && setTimeout(() => (this.activePicker2 = "Ano"));
    }
  },
  methods: {
    limparForm(){
      this.dadosProdutos = {}
      this.dadosPregao = {}
    },
    dateSave(date) {
      this.$refs.menu.save(date);
    },
    dateSave2(date) {
      this.$refs.menu2.save(date);
    },
    criarPregao() {    
       try{
         this.listaProdutos = this.dadosProdutos.filter(produto => produto !== undefined )
       }catch(erroo){
         showError('Dados do pregão não informado')
       }
      
      for(this.idsProdutos in this.listaProdutos) {
        this.listaProdutos[this.idsProdutos].listaIdPregao = this.dadosPregao.id
      }

      this.dadosPregao.saldoPregao = this.valorTotalPregao
      this.dadosPregao.valorEntregue = 0.00
      this.dadosPregao.listaProdutos = JSON.stringify(this.listaProdutos)

      axios
        .post(`${urlApi}/pregao`, this.dadosPregao)
        .then(res => {
          document.getElementById("js-modal-pregao__close").click()
          barramento.EnviarLoadPregao(true)
          this.limparForm()
          this.$toasted.global.defaultSuccess()
          })
        .catch(showError)
    },
  },
  created() {
    barramento.getProdutosPregao(produtosPregao => {
        this.dadosProdutos = produtosPregao
    }),

     barramento.getValorTotalPregao(valorTotalPregao => {
        this.valorTotalPregao = valorTotalPregao
    })
  }
};
</script>

<style>
.form-pregao__item {
  font-family: "Georama", sans-serif !important;
}
</style>