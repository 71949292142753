<template>
<div id="c-content" class="c-content">

  <div v-if="usuarioLogado" class="rounded-lg c-content__principal overflow-hidden">
    <v-slide-y-reverse-transition appear>
    <div class="c-content__display">
        <v-col class="pa-0 ma-0">
            <Menu />
        </v-col>
    </div>
    </v-slide-y-reverse-transition>
  </div>

  <v-container v-else class="c-content__login fill-height">
    <v-fade-transition appear>
    <v-row class="d-flex flex-column justify-center align-center" >
      <v-col>
        <login :sessaoExpirada="sessaoExpirada" />
      </v-col>
    </v-row>
    </v-fade-transition>
  </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { urlApi, showError, userKey } from "@/global";
import barramento from "@/barramento"
import Menu from "./Menu";
import login from "../usuario/login";

export default {
  name: "Content",
  components: { Menu, login },
  props:{
    usuarioLogado: Boolean,
    sessaoExpirada: Boolean
  },

};
</script>

<style>
.c-content__principal {
  grid-area: c-content;
  margin: 2%;
  margin-top: 3%;
  margin-bottom: 1%;
  min-width: 320px;
  max-width: 3342px;
  min-height: 480px;
  max-height:1500px;
  
}
.c-content__display {
  background: #fcfcfc;
  display: flex;
  padding: 0px;
  padding-top: 0px;
  border-radius: 15px;
  width: 100%;
  height: 100%;
}
</style>