var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.infoProduto,"item-key":"id","sort-by":"name","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar produto","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.dialogMetricas),callback:function ($$v) {_vm.dialogMetricas=$$v},expression:"dialogMetricas"}},[_c('v-card',{staticClass:"ma-0 pa-0"},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('metricasProdutos',{attrs:{"todosProdutos":_vm.consultarMetricas,"dataProduto":_vm.dataProduto},on:{"fecharMetricas":function($event){return _vm.fecharMetrica($event)}}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"primary","elevation":"9"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Editar URL"},model:{value:(_vm.editedItem.imageUrl),callback:function ($$v) {_vm.$set(_vm.editedItem, "imageUrl", $$v)},expression:"editedItem.imageUrl"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"7","md":"7"}},[_c('v-text-field',{attrs:{"label":"Editar Nome"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"btn-cancelar",attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.send}},[_vm._v("Salvar")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Tem certeza de que deseja excluir este item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.imageUrl,"alt":"Imagem","width":"70px","height":"auto"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center d-flex"},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.openDialogMetricas(item)}}},[_vm._v("insights")])],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }