<template>
  <div class="c-header">
    <v-slide-y-transition appear>
    <v-container fluid style="height: 300px">
      <v-row v-if="dadosUser.name !== undefined" justify="center">
        <v-col>
          <v-img class="c-header__logo" src="@/assets/logo-nutrimix-distribuidora.svg" contain max-height="auto"
            max-width="190"></v-img>
        </v-col>
        <v-col class="d-flex justify-end" align-self="end">
        <v-menu right bottom transition="slide-y-transition">
          <template v-slot:activator="{ on }">
            <v-btn text class=" white--text" x-large v-on="on">
                {{ dadosUser.name }}        
                <v-icon v-if="dadosUser.admin" class="pl-2 mr-2" right dark large color="white">mdi-shield-account</v-icon>       
                <v-icon v-else class="pl-2 mr-2" right dark large color="white">person</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="#943634" class="mb-2">
                 <v-icon dark>mdi-account-circle</v-icon>
                </v-avatar>
                <h3>{{ dadosUser.name }}</h3>
                <p class="text-caption mt-1">
                  {{ dadosUser.email }}
                </p>
                <v-divider class="my-3"></v-divider>
                
                <modalGestaoUsuario />

                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text color="#943634" @click="logout">
                 <v-icon left dark color="#943634">logout</v-icon>
                  Sair
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
        </v-col>
      </v-row>
    </v-container>
    </v-slide-y-transition>
  </div>
</template>

<script>
import axios from "axios"
import { urlApi, showError, userKey } from "@/global"
import modalGestaoUsuario from "../usuario/modalGestaoUsuario"
import barramento from "@/barramento"
export default {
  name: "Header",
  components: { modalGestaoUsuario },
  props:{
    dadosUser: {}
  },
  data: function(){
    return{
      user: {
        initials: 'JD',
        fullName: this.dadosUser.name,
        email: this.dadosUser.email,
        openMenu: true
      },
    }
  },
  methods:{
    logout(){
      localStorage.removeItem(userKey)
      barramento.enviarStatusLogin(true, false)
      }
  }
};
</script>

<style>
.c-header {
  grid-area: c-header;
  background: transparent;
  padding: 15px 25px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
</style>