<template>
  <v-data-table
    :items-per-page="totalItensPageToEmpenhos"
    :sort-by.sync="porcodigo"
    :sort-desc.sync="sortDesc"
    :headers="headers"
    :items="listaEmpenhos"
    item-key="id"
    class="pt-10 c-table-lista__empenho"
    :loading="loading"
    loading-text="Procurando Empenhos"
    :search="search">
    <template class="pb-10" width="100px" v-slot:top>
      <v-toolbar flat>
      <v-row class="justify-center align-center">   
        <v-col class="d-flex flex-column justify-start mb-0 pb-0" cols="1" sm="3" md="1">
          <v-switch
          class="c-btn-modo__edicao__empenho"
          v-model="modoEdicaoEmpenho"
          label="Modo Edição">
          </v-switch>

         <!--   <v-btn
           v-if="modoEdicaoEmpenho"
           class="ml-n15 mt-n3"
           absolute
           left
           @click="deleteUltimEmpenho">
            Excluir último empenho 
            </v-btn> -->

        </v-col>

        <v-col class="flex-grow-0 mb-0 pb-0" cols="3" sm="3" md="3">
        <v-text-field
          outlined
          class="ml-3"
          readonly
          label="Total do Pregão"
          :value="itemPregao.saldoPregao | dinheiro">
        </v-text-field>
        </v-col>

        <div class="u-hide">{{calcValorEntregue('valorTotalEmpenho')}} | {{verificarPendenciasNf}}</div>
        <div class="u-hide">{{valorRestante = itemPregao.saldoPregao - somaValorTotalEntregueEmpenho }}</div>

        <v-col class="flex-grow-0 mb-0 pb-0" cols="3" sm="3" md="3">
        <v-text-field
          outlined
          class="ml-3"
          readonly
          label="Valor Empenhado"
          :value="somaValorTotalEntregueEmpenho | dinheiro">
        </v-text-field>
        </v-col>

        <v-col class="flex-grow-0 mb-0 pb-0" cols="3" sm="3" md="3">
        <v-text-field
          outlined
          class="ml-3"
          readonly
          label="Valor restante"
          :value="valorRestante | dinheiro">
        </v-text-field>
        </v-col>

        <v-col class="mr-auto align-self-start mb-0 pb-0" cols="4" sm="4" md="4">
        <v-text-field
          hint="Busque Por: ID, Data ou Tipo"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar Empenho"
          single-line
        ></v-text-field>
        </v-col>
        </v-row>

        <v-dialog
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        v-model="dialogCadasEmpenhoItem">

            <template v-slot:activator="{ on, attrs }">
                <v-btn
                absolute
                right
                fab
                dark
                large
                class="mr-n6 mx-2"
                color="#943634"
                elevation="9"
                v-bind="attrs"
                v-on="on">
                  <v-icon large>post_add</v-icon>
                </v-btn>
            </template>

          <v-card>
            <v-toolbar class="mb-5" color="#943634" dark>
              <v-toolbar-title> <span class="text-h5">{{ formTitle }}</span></v-toolbar-title>
          <v-spacer></v-spacer>

           <v-toolbar-items>
            <v-btn class="mx-2" icon>
              <v-icon id="js-modal-empenho__close" dark large @click="fecharModalCadastrarEmpenho">cancel</v-icon>
            </v-btn>
          </v-toolbar-items>

          </v-toolbar>

            <v-card-text>
              <v-container class="c-container-cadastro__empenho">
                <v-row>

                  <v-col class="mb-0 pb-0" cols="12" lg="12" sm="12" md="12">                
                    <cadastroEmpenho
                    :somaValorTotalEntregueEmpenho="somaValorTotalEntregueEmpenho"
                    :isEmpenho="isEmpenho"
                    :itemPregao="itemPregao"
                    :listaEmpenhos="dadosUltimoEmpenho"></cadastroEmpenho> 
                  </v-col>
                  
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog persistent fullscreen hide-overlay transition="dialog-bottom-transition" v-model="dialogNotas">
          <v-card>
            <v-toolbar class="mb-5" color="#363494" dark>

              <v-toolbar-title>
                <span class="text-h6">Notas do Empenho ID: {{ itemEmpenho.idEmpenho }} |
                  Órgão: {{ itemEmpenho.nomeOrgao }} | Pregão: {{itemEmpenho.idRefPregao}} </span></v-toolbar-title>
              <v-spacer></v-spacer>

              <v-toolbar-items>
                <v-btn class="mx-2" icon>
                  <v-icon dark large @click="closeViewsNotas">cancel</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col class="d-flex justify-center mb-0 pb-0" cols="12" sm="12" md="12" lg="12">
                                     
                    <viewsNotas
                    :primeiroEmpenho="primeiroEmpenho"
                    :itemFiltrado="itemFiltrado"
                    :isNota="isNota"
                    :itemEmpenho="itemEmpenho"
                    :listaNotas="listaNotas"> </viewsNotas>
                   
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDeleteEmpenho" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Tem certeza de que deseja excluir este empenho?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDeleteEmpenho">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirmEmpenho">OK</v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>

    <template #item.id="{item}">
    <v-text-field class="c-table-lista__empenho__item c-table-lista__empenho__item__id u-hide-linha__input" label="id"
     readonly outlined type="text" :value='item.id'>
    </v-text-field>
    </template>

    <template #item.idEmpenho="{item}">
    <v-text-field class="c-table-lista__empenho__item u-hide-linha__input" label="Empenho"
     readonly outlined type="text" :value='item.idEmpenho'>
    </v-text-field>
    </template>

    <template #item.dataEmpenho="{item}">
        <v-text-field
        readonly
        outlined
        class="c-table-lista__empenho__item u-hide-linha__input u-width-fixa__115"
        label="Data Empenho"
        :value='formatData(item.dataEmpenho)'
        ></v-text-field>
    </template>

     <template #item.tipoEmpenho="{item}">
    <v-text-field class="c-table-lista__empenho__item u-hide-linha__input" label="Tipo"
     readonly outlined type="text" :value='item.tipoEmpenho'>
    </v-text-field>
    </template>

    <template #item.valorTotalEmpenho="{item}">
      <v-text-field
      class="c-table-lista__empenho__item u-hide-linha__input"
      label="Valor do Empenho"
      outlined
      readonly
      :value='item.valorTotalEmpenho | dinheiro'>
      </v-text-field>
    </template>

    <template #item.valEmitidoNF="{item}">
      <v-text-field
      class="c-table-lista__empenho__item u-hide-linha__input"
      label="Valor Emitido"
      outlined
      readonly
      :value='item.valEmitidoNF | dinheiro'>
      </v-text-field>
    </template>

    <template #item.quantidadeNf="{item}">

       <v-badge
        v-if="!item.notificaoNf && item.valEmitidoNF > 0"
        class="pa-0 ma-0"
        bordered
        color="green"
        icon="done_outline"
        overlap
      >
      <v-btn
      class="mx-2 pa-0 ma-0"
      fab
      dark
      small
      outlined
      color="#434343">
      {{item.quantidadeNf}}
      </v-btn>
      </v-badge>

      <v-badge
        v-else
        class="pa-0 ma-0"
        bordered
        color="error"
        icon="priority_high"
        overlap
      >
      <v-btn
      class="mx-2 pa-0 ma-0"
      fab
      dark
      small
      outlined
      color="#434343">
      {{item.quantidadeNf}}
      </v-btn>
      </v-badge>
      
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="text-center d-flex ">

        <v-btn v-if="modoEdicaoEmpenho && item.id == listaEmpenhos[listaEmpenhos.length - 1].id" class="mx-2 c-btn-delete__empenho__item" fab dark x-small color="#363494">
        <v-icon dark @click="delteEmpenho(item)">delete</v-icon>
        </v-btn>

      <v-btn class="mx-2" fab dark x-small color="#363494">
        <v-icon dark @click="notas(item)">receipt</v-icon>
      </v-btn>

      </div>
    </template>

  </v-data-table>
</template>

<script>
import axios from "axios";
import { urlApi, showError } from "@/global";
import barramento from "@/barramento"
import cadastroEmpenho from "../empenho/cadastroEmpenho"
import viewsNotas from "../notas/listaNotas"

export default {
  name: "listaEmpenho",
  props:{
    itemPregao: {},
    listaEmpenhos: [],
    isEmpenho: [Boolean, Function],
    modoEdicao: Boolean,
  },
  components: { cadastroEmpenho, viewsNotas },
  
  data: function() {
    return {
      porcodigo: 'id',
      sortDesc: true,
      search: "",
      dialogCadasEmpenhoItem: false,
      dialogNotas: false,
      dialogDeleteEmpenho: false,
      loading: true,

      headers: [
        { text: "ID", align: "start", sortable: true, value: "id"},
        { text: "Empenho", value: "idEmpenho" },
        { text: "Data", value: "dataEmpenho" },
        { text: "Tipo", value: "tipoEmpenho" },
        { text: "Valor", value: "valorTotalEmpenho"},
        { text: "Valor Emitido", value: "valEmitidoNF"},
        { text: "Quant. de Notas", class: 'tableHeadQuantNF', value: "quantidadeNf"},
        { text: "Ações", value: "actions", sortable: false }
      ],

      itemEmpenho: {},
      isNota: Boolean,
      listaNotas: [],
      dadosEmpenho: this.listaEmpenhos,
      editedIndex: -1,
      delProduto: {},
      editedItem: {},
      defaultItem: {},
      somaValorTotalEntregueEmpenho: 0,
      exec: false,
      primeiroEmpenho: false,
      itemFiltrado: [],
      modoEdicaoEmpenho: false,
      pendencias: 0,
      pendencia: {},
      dadosUltimoEmpenho: [],
      totalItensPageToEmpenhos: -1
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? `Cadastrar Empenho Para o Pregão: ${this.itemPregao.idPregao} Órgão: ${this.itemPregao.nomeOrgao}` : "Lista de Notas";
      },
    verificarPendenciasNf() {
      this.pendencias = 0
        if(!!this.listaEmpenhos.length){
       this.listaEmpenhos.map(itemEmpenho => itemEmpenho.notificaoNf).reduce((resultado, pendencia)=> {
         if(pendencia === true) {this.pendencias += 1}},this.listaEmpenhos[0].notificaoNf)
        }
        return this.pendencias == 0 ? false : true
      }
    },

  watch: {
    dialogCadasEmpenhoItem(val) {
      if(val) {
        this.exec = false
        if(this.listaEmpenhos.length > 0){
        this.dadosUltimoEmpenho = this.listaEmpenhos[this.listaEmpenhos.length - 1].listaProdutosEmpenho
        this.atualizarState(this.dadosUltimoEmpenho)
        }  
    }
      val || this.fecharModalCadastrarEmpenho()
    },
    dialogDeleteEmpenho(val) {
      val || this.closeDeleteEmpenho()
    },
    dialogNotas(val){
      val || this.fecharModalCadastrarEmpenho()
    },
    somaValorTotalEntregueEmpenho(novo, antigo) {
        if(novo != antigo) this.atualizarValorEntregue(novo)
    },
    verificarPendenciasNf(val){
      val ? this.atualizarNotficacaoNfEmp(true) : this.atualizarNotficacaoNfEmp(false)
    }

  },

  methods: {
    getUltimoEmpenho(){
      let idUltimoEmpenho = this.listaEmpenhos[this.listaEmpenhos.length - 1]
      axios
      .get(`${urlApi}/empenho/${idUltimoEmpenho.id}`, idUltimoEmpenho)
      .then(res=> {
        this.dadosUltimoEmpenho = res.data[0].listaProdutosEmpenho
        this.atualizarState(this.dadosUltimoEmpenho)
        })
      .catch(showError)
    },

     getNotas(id){
      axios.get(`${urlApi}/todasNotas/${id}`)
      .then(res => {
        this.listaNotas = res.data;
        this.listaNotas.length >= 1 ? this.isNota = true : this.isNota = false
        })
      .catch(showError)
      
    },
      atualizarState(item){
          if(!this.exec){
            for (let posicao in item){
              if(item[posicao].proxEmpenho == undefined){
                item[posicao].proxEmpenho = item[posicao].quantidadeProdutoPregao
                item[posicao].quantRestante = item[posicao].quantidadeProdutoPregao
              }
            item[posicao].state = item[posicao].proxEmpenho
            item[posicao].quantEmpenhoAtual = 0
            item[posicao].valorItemEmpenhoAtual = 0
        }
          this.exec =  true
        }
      },
      atualizarValorEntregue(valorEntregue){
        this.itemPregao.valorEntregue = valorEntregue
        axios.put(`${urlApi}/valEntregue/${this.itemPregao.id}`, this.itemPregao)
          .then(itemPregao => this.itemPregao.valorRestante =  this.itemPregao.saldoPregao - this.itemPregao.valorEntregue)
          .catch(showError)
      },

    calcValorEntregue(chave) {
       this.somaValorTotalEntregueEmpenho =  this.listaEmpenhos.reduce((a, b) => a + (b[chave] || 0), 0);
    },

    notas(item) {
      this.getNotas(item.id)

      this.itemFiltrado = item.listaProdutosEmpenho.filter(produto => produto.quantParaNota != 0)
      this.itemEmpenho = item
      this.primeiroEmpenho = item.isPrimeiro
  
      this.editedIndex = this.dadosEmpenho.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogNotas = true
    },

    delteEmpenho(item) {
      this.editedIndex = this.dadosEmpenho.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDeleteEmpenho = true
    },

    deleteItemConfirmEmpenho() {
        axios
        .delete(`${urlApi}/empenho/${this.editedItem.id}`, this.editedItem)
        .then(res => {
          this.dadosEmpenho.splice(this.editedIndex, 1)
          barramento.EnviarLoadEmpenho(true, this.itemPregao.id)
          this.modoEdicaoEmpenho = false
          this.$toasted.global.defaultSuccess()
          })
        .catch(showError)
      this.closeDeleteEmpenho()
    },

    closeDeleteEmpenho() {
        this.dialogDeleteEmpenho = false;
        this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    fecharModalCadastrarEmpenho() {
      this.dialogCadasEmpenhoItem = false
      barramento.EnviarLoadEmpenho(true, this.itemPregao.id)
    },
  
  closeViewsNotas() {
      this.dialogNotas = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    salve() {
      if (this.editedIndex > -1) {
        Object.assign(this.dadosEmpenho[this.editedIndex], this.editedItem)
        } else {
        this.dadosEmpenho.push(this.editedItem)
        }
        this.fecharModalCadastrarEmpenho()
    },
    
    formatData(data, index){

    if(data === null){
        return
    }
   else if (data.length > 10 ){

      let ano = ''
      let mes = ''
      let dia = ''

      data.split('-',2).filter((v, i , array) => ano = array[0]);
      data.split('-',2).filter((v, i , array) => mes = array[1]);
      data.match(/[\d{2}]+(?=T)/gi).filter((v, i , array) => dia = array[0]);
      
      return `${dia}/${mes}/${ano}`
      }
    },

    atualizarNotficacaoNfEmp(isTrue){
      this.pendencia.isNotificacao = true
      this.pendencia.notificaoNf = isTrue

      axios.patch(`${urlApi}/atualizarPregao/${this.itemPregao.id}`, this.pendencia)
      .then(res => {
        this.itemPregao.notificaoNf = isTrue
        })
      .catch(showError)
    }

  },
  mounted(){
   if(this.dadosEmpenho) this.loading = false;
 },

  created() {
    barramento.getNotas((istrue, id) =>{
      if(istrue) this.getNotas(id)
    })
  }
  
};
</script>

<style>
.u-hide-linha__input .v-input__slot:before, .u-hide-linha__input .v-text-field__details {
    content: "";
    display: none;
}
.c-table-lista__empenho .c-table-lista__empenho__item__id{
    width: 45px;
}
.c-table-lista__empenho table{
    margin-top: 80px;
}
.c-table-lista__empenho tr {
    height: 90px;
}
.c-table-lista__empenho td {
    padding: 20px 10px!important;
}

.c-table-lista__empenho tr {
    outline: solid 4px #ffffff!important;
    background: #f5f5f5;
}
.c-table-lista__empenho .v-data-table__wrapper {
    border-radius: 7px;
}
.c-table-lista__empenho {
    width: 1250px;
}
.c-table-lista__empenho tr {
    height: 12px;
}
.c-btn-modo__edicao__empenho {
    position: absolute;
    left: 0px;
    top: calc(0px - 44px);
}
.tableHeadQuantNF i {
    position: absolute!important;
}
.c-container-cadastro__empenho {
    margin: 0px;
    min-width: 100%;
}
</style>