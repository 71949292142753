<template>
<div>
   <v-alert v-if="sessaoExpirada" class="mb-10" border="right" colored-border type="error" elevation="2" dismissible>
     Sua sessão expirou!
     Por motivos de segurança sua sessão possui um prazo de três dias, para renovar seu prazo basta entrar com suas credenciais novamente.
   </v-alert>
   <v-slide-y-transition appear>
  <v-row class="d-flex justify-center pb-10">
    <v-img class="c-header__logo" src="@/assets/logo-nutrimix-distribuidora.svg" contain max-height="auto"
      max-width="280"></v-img>
  </v-row>
  </v-slide-y-transition>
<v-slide-y-reverse-transition appear>
  <v-card class="mx-auto" max-width="344" flat elevation="20" shaped>
    <v-row class="d-flex flex-column pa-5">
         <v-col>
            <v-text-field
            v-model="user.email"
            type="email"
            name="email"
            append-icon="email"
            :rules="[rules.required, rules.email]"
            label="E-mail"
            >
            </v-text-field>
        </v-col>

        <v-col>
            <v-text-field
            :append-icon="trocarIconeLogin ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="trocarIconeLogin ? 'text' : 'password'"
            name="password"
            label="Senha"
            class="input-group--focused"
            @click:append="trocarIconeLogin = !trocarIconeLogin"
            v-on:keyup.enter="signin"
            v-model="user.password"
          ></v-text-field>
        </v-col>

        <v-col class="d-flex justify-end mt-n5">
        <v-btn color="#943634" class="white--text" @click="signin" v-on:dblclick.prevent="">
            ENTRAR
        </v-btn>
        </v-col>
    </v-row>
</v-card>
</v-slide-y-reverse-transition>
</div>
</template>

<script>
import axios from "axios";
import { urlApi, showError, userKey } from "@/global";
import barramento from "@/barramento"
export default {
    name: 'CardDeLogin',
    props:{
      sessaoExpirada: Boolean
    },
    data: function(){
        return{
            user: {},
            trocarIconeLogin: false,
            rules: {
                required: value => !!value || 'Campo obrigatório.',
                min: v => {
                  if(v == undefined){
                    return 'Digite uma senha'
                    }else{
                      return v.length >= 8 || 'Min de 8 caracteres'
                    }
                  },
                email: value => {
                  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  return pattern.test(value) || 'Email inválido.'
                  }
          }
        }
    },

    methods:{
      signin() {
        axios.post(`${urlApi}/signin`, this.user)
        .then(res => {
          localStorage.setItem(userKey, JSON.stringify(res.data))
          axios.defaults.headers.common['Authorization'] = `bearer ${res.data.token}`
          this.$toasted.global.defaultSuccess()
          barramento.enviarStatusLogin(true, false)    
          })
          .catch(showError)
    },

    }

}
</script>
<style>

</style>