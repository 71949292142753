<template>
  <v-app class="s-app">
    <div id="app">
      <Header :dadosUser="dadosUser" />
      <Loader v-if="validatingToken" />
      <Content v-else :usuarioLogado="usuarioLogado" :sessaoExpirada="sessaoExpirada" />
      <Footer />
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import { urlApi, showError, userKey } from "@/global";
import barramento from "@/barramento"
import Header from "./components/tm/Header";
import Content from "./components/tm/Content";
import Footer from "./components/tm/Footer";
import Loader from "./components/tm/Loader";

export default {
  name: "App",
  components: { Header, Content, Footer, Loader },
  data: function(){
    return{
      validatingToken: true,
      usuarioLogado: false,
      sessaoExpirada: false,
      dadosUser: {},
    }
  },
 methods: {
   async validateToken() {
			this.validatingToken = true

			const json = localStorage.getItem(userKey)
			const userData = JSON.parse(json)
      this.usuarioLogado = false
      this.dadosUser = {}

			if(!userData) {
				this.validatingToken = false
        this.usuarioLogado = false
        this.dadosUser = {}
				return
			}
			const res = await axios.post(`${urlApi}/validateToken`, userData)

			if (res.data) {
        axios.defaults.headers.common['Authorization'] = `bearer ${userData.token}`
				this.usuarioLogado = true
        this.dadosUser = userData

        if(res.data.admin === false){
          localStorage.removeItem(userKey)
          this.sessaoExpirada = true
          this.usuarioLogado = false
          this.dadosUser = {}
          return
          }
			
			} else {
				localStorage.removeItem(userKey)
			  this.usuarioLogado = false
        this.dadosUser = {}
			}

			this.validatingToken = false
		},
	},
	created() {
		this.validateToken()
    barramento.getStatusLogin((isTrue, sessaoExpirada) => {
      if(isTrue){
        this.validateToken()
        this.sessaoExpirada = sessaoExpirada
      }
      isTrue = false;
    })
	}
};
</script>

<style>
* {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 500;
  box-sizing: border-box;
}
.text-h5{
  word-break: break-word;
}
.u_largura-90{
  width: 135px;
}
.u-largura-135 {
    width: 135px;
}
.u-width-fixa__115 {
    width: 115px;
}
.u-width-fixa__220{
    width: 220px
}
.u-width-fixa__320{
    width: 320px;
}
.u-width-fixa__520{
    width: 520px;
}
.s-app {
  background: transparent!important;
}
body {
  margin: 0px;
  background: #943634;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  display: grid;
  grid-template-rows: 70px 1fr 40px;
  grid-template-columns: 1fr;
  grid-template-areas:
    "c-header"
    "c-content"
    "c-footer";
}
.c-table__nome {
    font-size: 10px;
    width: 100px;
    font-weight: 900;
}
.u-absolute{
  position:absolute;
}
.u-relative{
  position:relative;
}
.u-color{
  color:#943634!important;
}
.u-bg{
  color:#943634;
}
.u-select{
  user-select: none;
}
.u-pointer{
  cursor: pointer;
}
/*ScrolBar*/

::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #bcbbbb;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #246499;
}
::-webkit-scrollbar-track {
  background: #ab3332;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #246499;
}
::-webkit-scrollbar-track:active {
  background: #246499;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.u-hide{
  display: none;
}
</style>