import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted,{
    iconPack: 'fontawesome',
    duration: 2500
})

Vue.toasted.register(
    'defaultSuccess',
    payload => !payload.msg ? 'Operação realizada com sucesso!': payload.msg,
    { type: 'success', icon: 'check_circle'}
)

Vue.toasted.register(
    'defaultError',
    payload => !payload.msg ? 'Operação... Erro Inesperado!': payload.msg,
    { type: 'error', icon: 'report_problem'}
)