<template>
  <v-form>
    <v-row >
      <v-main>
      <v-row class="d-flex space-between px-lg-15 py-lg-3">
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          outlined
          cols="12"
          sm="12"
          md="4"
          hint="Código do novo epenho"
          v-model="dadosEmpenho.idEmpenho">
          <template class="m-p-4" v-slot:label>
            <strong>ID DO EMPENHO</strong>
            <v-icon style="vertical-align: middle">mdi-key-variant</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          origin="center center"
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="dadosEmpenho.dataEmpenho"
              label="DATA DO EMPENHO"
              hint="Data deste empenho"
              persistent-hint
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dadosEmpenho.dataEmpenho"
            :active-picker.sync="activePicker"
            min="1950-01-01"
            @change="dateSave"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-btn block x-large color="#943634" @click="criarEmpenho" v-on:dblclick.prevent>
          <v-icon left color="#fff">mdi-clipboard-check-outline</v-icon><span style="color:white;">CADASTRAR EMPENHO</span>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="4">
       <v-select
        v-model="dadosEmpenho.tipoEmpenho"
        :items="tipoEmpenho"
        dense
        chips
        label="Tipo do Empenho">
        </v-select>
      </v-col>
      </v-row>
      </v-main>

       <v-col v-if="isEmpenho" cols="12" lg="12" md="12" sm="12">
        <listaProdutosEmpenho
        :somaValorTotalEntregueEmpenho="somaValorTotalEntregueEmpenho"
        :listaEmpenhos="listaEmpenhos">
        </listaProdutosEmpenho>
      </v-col>

       <v-col v-if="!isEmpenho" cols="12" lg="12" md="12" sm="12"> 
        <listaProdutosEmpenhoStart v-if="!isEmpenho"
        :listaProdutosPregao="itemPregao">
        </listaProdutosEmpenhoStart>
      </v-col>

    </v-row>
  </v-form>
</template>
<script>
import listaProdutosEmpenho from "./listaProdutosEmpenho";
import listaProdutosEmpenhoStart from "./listaProdutosEmpenhoStart";
import axios from "axios";
import { urlApi, showError } from "@/global";
import barramento from "@/barramento";
export default {
  name: "cadastroEmpenho",
  props:{
    itemPregao: {},
    listaEmpenhos: [],
    isEmpenho: [Boolean, Function],
    somaValorTotalEntregueEmpenho: [String, Number],
  },
  components: { listaProdutosEmpenho, listaProdutosEmpenhoStart },

  data: () => ({
    tipoEmpenho: ['Global', 'Ordinário'],
    dadosEmpenho: {},
    listaProdNovoEmpenho: [],
    bdPempenho: [],
    idsEmpenhos: '',
    valorTotalEm: 0,
    valorTotalGeralEm: 0,
    activePicker: null,
    activePicker2: null,
    date: null,
    date2: null,
    menu: false,
    primeiroEmpenho: false,
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "Ano"));
    },
  },
  methods: {
    limparForm(){
      this.dadosEmpenho = {}
        document.querySelectorAll('.c-table-empenho .u-clear-data input').forEach(quantidade => quantidade.value = 0)
    },

    dateSave(date) {
      this.$refs.menu.save(date);
    },
    criarEmpenho() {
      this.bdPempenho = this.listaProdNovoEmpenho.filter(produto => {
        if(produto.quantEmpenhoAtual == 0) produto.quantParaNota = 0
        return produto !== undefined})

      this.dadosEmpenho.idRefPregao = this.itemPregao.id
      this.dadosEmpenho.nomeOrgao = this.itemPregao.nomeOrgao
      this.dadosEmpenho.saldoPregao = this.itemPregao.saldoPregao
      this.dadosEmpenho.valorTotalEmpenho = this.valorTotalEm
      this.dadosEmpenho.isPrimeiro = this.primeiroEmpenho
      this.dadosEmpenho.valEmitidoNF = 0
      
      this.dadosEmpenho.listaProdutosEmpenho = JSON.stringify(this.bdPempenho)

        axios
        .post(`${urlApi}/empenho`, this.dadosEmpenho)
        .then(res => {
          barramento.EnviarLoadEmpenho(true, this.itemPregao.id)
          this.limparForm()
          document.getElementById("js-modal-empenho__close").click()
          this.$toasted.global.defaultSuccess()
          })
        .catch(showError)
    },
  },

  created() {
    barramento.getProdutosEmpenho((listaEmpenho, isPrimeiro) => {
        this.listaProdNovoEmpenho = listaEmpenho
        this.primeiroEmpenho = isPrimeiro
    })
    barramento.getTotalEmpenho(vtotal => {
        this.valorTotalEm = vtotal
    })
  },

};
</script>

<style>
.form-pregao__item {
  font-family: "Georama", sans-serif !important;
}
</style>