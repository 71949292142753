<template>
  <v-data-table
    :headers="headers"
    :items="(listaProdutosNota = itemFiltrado)"
    :items-per-page="totalItensPage"
    item-key="id"
    sort-by="Produto"
    class="mt-2 c-table-nota"
    :loading="loading"
    :search="search"
    hide-default-header
  >
    <template v-slot:top>
      <v-toolbar flat prominent class="mb-5">
        <v-row class="justify-center align-center">
          <v-col class="mb-0 pb-0" cols="12" sm="12" md="4">
            <!--  Calcular valor total empenho -->
            <div class="u-hide">{{ calcValorNota("valorDoItemNaNota") }}</div>
            <v-text-field
              outlined
              readonly
              label="Valor do Empenho"
              prefix="Empenho de:"
              :value="itemEmpenho.valorTotalEmpenho | dinheiro"
            >
            </v-text-field>
          </v-col>

          <v-col class="mb-0 pb-0" cols="12" sm="12" md="4">
            <v-text-field
              class="ml-3"
              outlined
              readonly
              label="Valor da Nota"
              prefix="Atual:"
              :value="valorTotalNota | dinheiro"
            >
            </v-text-field>
          </v-col>

          <v-col class="mb-0 pb-0" cols="12" sm="12" md="5">
            <v-text-field
              hint="Busque por: Nome do Produto"
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar produto"
              single-line
            ></v-text-field>
          </v-col>
        </v-row>
        <v-dialog v-model="dialogEditarValoresNfSt" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col class="mb-0 pb-0" cols="12" sm="12" md="6">
                    <v-text-field
                      disabled
                      :value="editedItem.name"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col class="mb-0 pb-0" cols="12" sm="12" md="3">
                    <v-text-field
                      label="Quantidade Restante"
                      disabled
                      type="number"
                      v-model.number="editedItem.quantParaNota"
                      outlined
                    ></v-text-field> </v-col
                  ><v-spacer md="5"></v-spacer>

                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      disabled
                      type="number"
                      outlined
                      v-model="editedItem.idGenericaProduto"
                      label="ID do Item"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="12" md="4">
                    <v-text-field
                      class="u-clear-data"
                      :rules="rulesQuantidade"
                      outlined
                      type="number"
                      v-model.number="editedItem.emitidaNaNota"
                      label="Adicionar Quantidade Emitida na NF"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="btn-cancelar"
                color="blue darken-1"
                text
                @click="closeEditarValNfSt"
                >Cancelar</v-btn
              >
              <v-btn
                v-if="editedItem.emitidaNaNota > editedItem.quantParaNota"
                color="blue darken-1"
                text
                @click="salveDadosNotaStart"
                disabled
                >Salvar</v-btn
              >
              <v-btn
                v-else
                color="blue darken-1"
                text
                @click="salveDadosNotaStart"
                >Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

      <!-- Dados da tabela -->

      <template #item.imageUrl="{item}">
      <img position="right right" class="mt-3 mb-0 rounded-lg elevation-1" :src="item.imageUrl" alt="Imagem" width="70px" height="auto" /><br />
      <p class="c-table__nome ma-0 pa-0">{{item.name}}</p>
      <p class="c-table__nome">Item: {{item.idGenericaProduto}} | {{ item.tipoUnidade != null ? Object.values(item.tipoUnidade)[0] : "-"}}</p>
    </template>

    <template #item.valorDeCompra="{ item }">
      <v-currency-field
        readonly
        class="c-table-empenho__item"
        prefix="R$"
        :value="item.valorDeCompra"
        hint="VALOR DE COMPRA"
        persistent-hint
        disabled
      />
    </template>

    <template #item.valorProdutoPregao="{ item }">
      <v-text-field
        class="c-table-nota__item"
        hint="VALOR DE VENDA NO PREGAO"
        persistent-hint
        disabled
        :value="item.valorProdutoPregao | dinheiro"
      >
      </v-text-field>
    </template>

    <template #item.quantParaNota="{ item }">
      <v-text-field
        class="c-table-nota__item"
        disabled
        type="number"
        v-model.number="item.quantParaNota"
        hint="QUANTIDADE DO EMPENHO"
        persistent-hint
      ></v-text-field>
    </template>

    <template #item.valorItemEmpenho="{ item }">
      <div class="u-hide">
        {{
          (item.valorItemEmpenho = item.quantParaNota * item.valorProdutoPregao)
        }}
      </div>
      <v-text-field
        class="c-table-nota__item"
        hint="VALOR DO ITEM NO EMPENHO"
        persistent-hint
        disabled
        :value="item.valorItemEmpenho | dinheiro"
      ></v-text-field>
    </template>

    <template #item.quantParaEmitir="{ item }">
      <div class="u-hide">
        {{ (item.quantParaEmitir = item.quantParaNota - item.emitidaNaNota) }}
      </div>
      <div class="u-hide">
        {{ (item.quantNotaState = item.quantParaEmitir) }}
      </div>
      <v-text-field
        class="c-table-nota__item"
        disabled
        type="number"
        v-model.number="item.quantParaEmitir"
        hint="QUANTIDADE RESTANTE PARA EMITIR"
        persistent-hint
      ></v-text-field>
    </template>

    <template #item.emitidaNaNota="{ item }">
      <v-text-field
        readonly
        class="u-clear-data c-table-empenho__item"
        type="number"
        v-model.number="item.emitidaNaNota"
        hint="QUANTIDADE EMITIDA NA NOTA"
        persistent-hint
      ></v-text-field>
    </template>

    <template #item.valorDoItemNaNota="{ item }">
      <div class="u-hide">
        {{
          (item.valorDoItemNaNota =
            item.emitidaNaNota * item.valorProdutoPregao)
        }}
      </div>
      <v-text-field
        class="u-clear-data c-table-empenho__item"
        hint="VALOR DO ITEM NA NOTA"
        persistent-hint
        disabled
        :value="item.valorDoItemNaNota | dinheiro"
      >
      </v-text-field>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="text-center d-flex">
        <v-btn class="mx-2" fab dark x-small color="primary">
          <v-icon dark @click="editItemQuantidadeNfStart(item)"
            >mdi-pencil</v-icon
          >
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";
import { urlApi } from "@/global";
import barramento from "@/barramento";

export default {
  name: "listaProdutosNotaStart",
  props: {
    itemEmpenho: {},
    itemFiltrado: [],
  },
  data: function () {
    return {
      loading: false,
      search: "",
      dialogEditarValoresNfSt: false,

      headers: [
        { text: "Imagem", align: "start", sortable: false, value: "imageUrl" },
        {
          class: "u-hide",
          cellClass: "u-hide",
          text: "Produto",
          value: "name",
        },
        {
          class: "u-hide",
          cellClass: "u-hide",
          text: "N°Item",
          value: "idGenericaProduto",
        },
        {
          class: "u-hide",
          cellClass: "u-hide",
          text: "Un. de Medida",
          align: "center",
          value: "tipoUnidade",
        },
        { text: "Valor de Compra", value: "valorDeCompra" },
        { text: "Valor do Item Cadastrado", value: "valorProdutoPregao" },
        { text: "Quantidade Cadastrada", value: "quantParaNota" },
        { text: "Valor Total Cadastrado", value: "valorItemEmpenho" },
        { text: "Quantidade Restante", value: "quantParaEmitir" },
        { text: "Quantidade do Empenho", value: "emitidaNaNota" },
        { text: "Quantidade Restante", value: "valorDoItemNaNota" },
        { text: "Ação", value: "actions", sortable: false },
      ],

      valorTotalNota: 0,
      valorTotalGeralEntregue: 0,
      listaProdutosNota: this.itemFiltrado,
      editedIndex: -1,
      delProduto: {},
      editedItem: {},
      defaultItem: {},

      rulesQuantidade: [
        (value) =>
          value <= this.editedItem.quantParaNota ||
          "Quantidade superior ao saldo",
      ],
      totalItensPage: -1,
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Adicionar Produto"
        : "Add Quantidade deste Empenho";
    },
  },

  watch: {
    dialogEditarValoresNfSt(val) {
      val || this.closeEditarValNfSt();
    },
  },

  methods: {
    calcValorNota(chave) {
      this.valorTotalNota = this.listaProdutosNota.reduce(
        (a, b) => a + (b[chave] || 0),
        0
      );
      barramento.EnviarTotalNota(this.valorTotalNota);
    },

    editItemQuantidadeNfStart(item) {
      this.editedIndex = this.listaProdutosNota.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEditarValoresNfSt = true;
    },

    closeEditarValNfSt() {
      this.dialogEditarValoresNfSt = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    salveDadosNotaStart() {
      if (this.editedIndex > -1) {
        Object.assign(
          this.listaProdutosNota[this.editedIndex],
          this.editedItem
        );
      }
      this.listaProdutosNota[this.editedIndex].emitidaNaNotaAtual = 0;
      this.listaProdutosNota[this.editedIndex].registroEmissaoNf =
        this.listaProdutosNota[this.editedIndex].emitidaNaNota;

      barramento.EnviarProdutosNota(this.listaProdutosNota);
      this.closeEditarValNfSt();
    },
  },
};
</script>
    
<style>
.textarea-nome .v-input__slot:before {
  content: "";
  display: none;
}
.textarea-nome .v-text-field__details {
  display: none;
}

.textarea-nome textarea {
  line-height: 14px !important;
}
.c-table-nota__item {
  width: 90px;
}
.c-table-nota__item__id {
  width: 50px;
}
.c-table-nota .v-messages__message {
  font-size: x-small;
  font-weight: 700;
}
</style>